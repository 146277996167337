import React, {createContext, useState} from 'react'

export const GlobalContext = createContext({})

export default function GlobalProvider({ children}){
    const [lang, setLang] = useState('uz')
    return(
        <GlobalContext.Provider value={{  lang, setLang }}>
            {children}
        </GlobalContext.Provider>
    )
}