import React from 'react'
import Contacts from './Contacts'
import Diu from '../Home/Diu'
import Karta from '../Home/Karta'

const Contact = () => {
  return (
    <div>
      <Contacts/>
      {/* <Xizmatlar/> */}
      <Karta/>
      {/* <Diu/> */}

    </div>



  )
}

export default Contact