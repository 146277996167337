import React, { useContext, useEffect, useState} from 'react'
import arrow from "../../assets/images/icons/arrow.png"
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../GlobalContext'
import { API_PATH } from '../../constants/tools'
import axios from 'axios'
import { GET_CENTER_DATA } from '../../constants/urls'

const CenterData = () => {
  const navigation = useNavigate()
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async() => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_CENTER_DATA)}`)
    if(info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])

  return (
    <div className='tempus'>
      <h2>Markaz faoliyatiga <br/><span> oid </span> hisobotlar. </h2>
      <div className="tempus__cards">
        {data?.length ? data?.map((item, index) => (
          <div 
              className="tempus__card" 
              key={index} 
              style={{ cursor: 'pointer' }}
              onClick={() => navigation('/moretempus', { state: [
                {
                  title: item?.title,
                  content: item?.content,
                  image: item?.image,
                  sub_title: item?.sub_title, 
                  sub_image: item?.sub_image, 
                  sub_content: item?.sub_content
                } 
              ]})}>
            <div className="tempus__card__info">{item?.title}</div>
            <div className="tempus__card__img"><img src={item?.image} alt="" /></div>
            <div className="tempus__card__link" style={{ fontSize: 20 }}>Batafsil<img src={arrow} alt="arrow"/></div>
          </div>
        )) : ''}
      </div>
    </div>
  )
}

export default CenterData