import React from "react";
import Consectetur from "./Consectetur";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_PATH } from "../../../constants/tools";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function RR() {
    const [id, setId] = useState({});
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const T = useTranslation();

    const getById = async () => {
        await axios
            .get(API_PATH + `/${T.i18n.language}/aboutcenter/yoshlar-siyosati/${path}/`)
            .then((res) => {
                setId(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getById();
    }, [path, T.i18n.language]);

    return (
        <section className="ReadMoreNews">
            <div className="containerr">
                <img className="raedmorenews-img1" src={id.image} alt={id.title} />
                <div className="readmorenews-info1">
                    <h2>{id.title}</h2>
                </div>

                <div className="raedmorenews-info2">
                    <p className="" dangerouslySetInnerHTML={{ __html: id?.content }} />
                    <div className=" row">
                        {id.new_detail &&
                            id.new_detail.map((item2, index2) => (
                                <div key={index2} className="col-12 mb-4">
                                    <img className="detail_img" key={index2} src={item2.image} alt={item2.content} />
                                    <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: item2?.content }} />
                                </div>
                            ))}
                    </div>
                </div>

                <Consectetur />
            </div>
        </section>
    );
}
