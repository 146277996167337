import React from 'react'
import Decrees from './Decrees'
import { useTranslation } from 'react-i18next';

export default function Documents({changeLang}) {
  const { t } = useTranslation();
  const changeLanguage = (e)=>{
    changeLang(e.target.value)
  }
  return (
    <section className='Documents'>
      <div className="containerr">
        <h2>{t("ukaz")}</h2>
        <Decrees/>
      </div>
    </section>
  )
}
