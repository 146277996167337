import React from 'react'
// import MoreTemousImg from '../../../assets/images/icons/commiteinfoimg1.png'
// import MoreTempusSlider from './MoreTempusSlider'
import { useLocation } from 'react-router-dom'

export default function MoreTempus() {
  const { state } = useLocation()

  console.log(state)
  return (
    <section className='Moretempus'>
      <div className="containerr">
        <div className='moretempus-info'>
          {state[0]?.image ? <img className='moretempus-img' src={state[0]?.image} alt="" /> : ''}
          <div className='moretempus-info__info'>
            <h2 dangerouslySetInnerHTML={{ __html: state[0]?.title }}></h2>
            <p dangerouslySetInnerHTML={{ __html: state[0]?.content }}/>
          </div>
        </div>


        <div className="moretempus-info2">
          <h3 dangerouslySetInnerHTML={{ __html: state[0]?.sub_title }}/>
          <p dangerouslySetInnerHTML={{ __html: state[0]?.sub_content }}/>
          <img src={state[0]?.sub_image} alt="" />
        </div>

        {/* <MoreTempusSlider/> */}
      </div>
    </section>
  )
}
