import React from "react";
import Consectetur from "./Consectetur";
import CarouselFadeExample from "./CarouselFadeExample";
import Kalendar from "./Kalendar";
import Partner from "./Partner";
import Contact from "../Contacts/Contact";

const Home = () => {
  return (
    <>
      <CarouselFadeExample/>
      <Consectetur />
      <Kalendar/>
      <Partner/>
      <Contact/>
    </>
  );
};

export default Home;
