const translationsUzKril = {
  home: "АСОСИЙ",
  about: "МАРКАЗ ХАҚИДА",
  activity: "ФАОЛИЯТ",
  services: "ОЧИҚ МАЪЛУМОТЛАР",
  contact: "АЛОҚА",
  herotittle: "ДАВЛАТ ЭКОЛОГИК ЭКСПЕРТИЗА МАРКАЗИ",
  staff: "ХОДИМЛАР",
  followUs: "Бизни кузатиб боринг",
  number: "Номер",
  address: "Манзил",
  email: "Электрон почта",
  latestnews: "Сўнги янгиликлар",
  news: "Янгиликлар",
  documents: "Қонунчилик ҳужжатлари",
  services2: "Хизматлар",
  hotlines: "Ишонч телефонлари",
  services_info: "сайт орқали кўрсатиладиган интерактив хизматлар рўйҳати",
  see_all: "Хаммасини кўриш",
  more: "Батафсил",
  xizmat1: "Ижтимоий соҳани, ривожлантириш давлат дастурлари, концепциялари ва схемаларининг, шунингдек, давлат томонидан муҳофаза этиладиган табиий ҳудудларни бошқариш режаларининг лойиҳаларини давлат экологик экспертизасидан ўтказиш",
  xizmat2: "Ишлаб чиқариш кучлари, иқтисодиёт тармоқларини жойлаштириш ва ривожлантириш концепциялари, схемаларининг лойиҳаларини давлат экологик экспертизасидан ўтказиш",
  xizmat3: "Барча турдаги қурилиш учун ер участкаларини танлаш материалларини давлат экологик экспертизасидан ўтказиш",
  xizmat4: "Фаолият турлари бўйича лойиҳаолди ва лойиҳа ҳужжатларини давлат экологик экспертизасидан ўтказиш",
  xizmat5: "Табиий ресурслардан фойдаланиш билан боғлиқ хўжалик ва бошқа фаолиятни тартибга солувчи норматив-техник ва йўриқнома-услубий ҳужжатларнинг лойиҳаларини давлат экологик экспертизасидан ўтказиш",
  xizmat6: "Техника, технология, материаллар, моддалар, маҳсулотларнинг янги турларини яратишга доир ҳужжатларни давлат экологик экспертизасидан ўтказиш",
  xizmat7: "Атроф табиий муҳитга ва фуқароларнинг соғлиғига салбий таъсир кўрсатувчи ишлаб турган корхоналар ва бошқа объектларни давлат экологик экспертизасидан ўтказиш",
  xizmat8: "Ҳудудларга кейинчалик муҳофаза қилинадиган табиий ҳудудлар, фавқулодда экологик вазият ва экологик офат зоналари мақомини бериш мақсадида уларни комплекс текшириш материалларини давлат экологик экспертизасидан ўтказиш",
  xizmat9: "Шаҳарсозлик ҳужжатларини давлат экологик экспертизасидан ўтказиш",
  connect_span: "Биз",
  connect: " билан боғланиш",
  region_tittle: "Ўзбекистон Республикаси Экология ва атроф-муҳитни муҳофаза қилиш  давлат қўмитасининг “Давлат экологик экспертизаси маркази”   Қорақалпоғистон Республикаси, вилоятлар ва тошкент шаҳар филиаллари   ходимлари тўғрисида МАЪЛУМОТЛАР",
  user_data: "Туғилган вақти ва жойи",
  fio: "Ф.И.Ш",
  user_tell: "Телефон рақами",
  appointment: "Лавозими",
  education: "Маълумоти ва мутахассислиги",
  language: "oz",
  list: "Батафсил",
  location: "Тошкент шаҳар, Мирзо Улуғбек тумани, Сайрам кучаси, 15-уй",

  rahbar4_4: "Марказий Аппарат",
  rahbar1_1: "Марказ филиаллари рахбарлари",
  rahbar1: "Давлат экологик экспертизаси маркази ходимлари тўғрисида",
  rahbar2: "Ф.И.Ш",
  rahbar3: "Қабул қуйидаги манзил бўйича амалга оширилади",
  rahbar4: "Телефон рақами",
  rahbar5: "Лавозими",
  rahbar6: " E-mail манзили",
  rahbar8: " Қабул вақтлари",

  nav_19_1: " Халқаро алоқалар",
  rahbar_2: `Хулосалар`,
  rahbar_3: `Лойиҳачилар рейтинги`,
  rahbar_4: `Aхборот хизмати`,
  rahbar_5: `Жамоатчилик эштуви`,
  nav_1_2: `Ташкилий тузилма`,


  nav_3_2: `Ҳудудий филиаллар `,
  nav_4_2: `Қонунчилик хужжатлари`,
  nav_5_2: `Очиқ маълумотлар`,


  // korrupsion

  kor1: "ЎЗБЕКИСТОН  РЕСПУБЛИКАСИ ЭКОЛОГИЯ ВА АТРОФ-МУҲИТНИ МУҲОФАЗА ҚИЛИШ ДАВЛАТ ҚЎМИТАСИНИНГ ДАВЛАТ ЭКОЛОГИК ЭКСПЕРТИЗАСИ МАРКАЗИ “КОРРУПЦИЯГА ҚАРШИ КУРАШИШ БОРАСИДАГИ АСОСИЙ ЙЎНАЛИШЛАР“",
  kor2: "Кириш",
  kor3: "Коррупцияни	белгиловчи омиллар	ва	унинг	келиб	чиқиш  сабаблари",
  kor4: "Коррупцияга қарши кураш борасида давлат сиёсатининг асосий  йўналишлари",
  kor5: "Ўзбекистон	Республикасининг	коррупцияга	қарши  курашиш тизими ривожланиши",
  kor6: "Коррупция",
  kor7: "Коррупция (лотинча:  corruptio — айниш, порага сотилиш)  шахснинг ўз мансаб ёки хизмат мавқейидан шахсий манфаатларини ёҳуд ўзга шахсларнинг манфаатларини кўзлаб моддий ёки номоддий наф олиш мақсадида қонунга хилоф равишда фойдаланиши, ҳудди шунингдек бундай нафни қонунга хилоф равишда тақдим этиш,  мансабдор шахсларни сотиб олиш, уларнинг порага сотилиши; сайланадиган, тайинланадиган лавозимларнинг сотилиши;",
  kor8: "Лоббизм (монополияларнинг қонунчилик органлари ва амалдорларга тазйиқ ўтказиш билан шуғулланадиган муассасалар тизими). Халқаро даражада 1970-йилларда Японияга самолётлар сотишда компания томонидан олий давлат амалдорларини сотиб олиш бўйича Локцид иши энг йирик коррупция кўринишларига мисол бўлади. Порани ҳаспўшлаш учун йирик келишувларда кўпроқ хизмат ҳақи тўлаш амалиётидан фойдаланилади.",
  kor9: "МАНФААТЛАР ТЎҚНАШУВИНИНГ КЎРИНИШЛАРИ",
  kor10: "Лоббизм (инглиз. lobby – кулуар, коридор)",
  kor11: "норасмий шахсларнинг ўзаро  учрашиб, расмий қарорлар қабул  қилишдаги сиёсий таъсирнинг  ўзига хос тури",
  kor12: "Раҳнамолик, фаворитизм  (фр. favorit)",
  kor13: "химоя остидаги айрим  ходимларни яқин олиш ва  қуллаб-қувватлаш",
  kor14: "Протекционизм (лот. кўмаклашиш)",
  kor15: "маълум бир шахсларни лавозимга  тайинлашга кўмаклашиш",
  kor16: "Непотизм (лот. nepos жиян)",
  kor17: " этник принциплар асосида  давлат тузилмаларида ёки бошқа тузилмаларда тор гуруҳчилик  манфаатлари билан иш кўриш",
  kor18: "Клиентелизм (ингл. clientelism,  лот. clientēla - мижоз)",
  kor19: "лавозимдаги мансабдор шахс ва унга хайрихох бўлган шахслар (мансабдор шахслар,  тадбиркорлар, сиёсий гуруҳлар)",
  kor20: "Кронизм	(инглиз.	хамфикр, таниш биличилик, гуруҳбозлик)",
  kor21: "лавозимларни таниш-билишчилик	асосида бериш",
  kor22: "Коррупцияни белгиловчи омиллар ва унинг келиб чиқиш сабаблари",
  kor23: "Коррупцияга оид  ҳуқуқбузарлик - коррупция  аломатларига эга бўлган, содир этилганлиги учун  қонун ҳужжатларида  жавобгарлик назарда  тутилган қилмиши  тушунилади",
  kor24: "Коррупция атамаси бузиш, тизимни издан  чиқариш, пора эвазига  оғдириш  деган  маънони англатадиган  лотинча corruptio сўзидан келиб чиққан",
  kor25: "Коррупция — шахснинг ўз мансаб ёки хизмат мавқеидан шахсий манфаатларини ёхуд ўзга  шахсларнинг манфаатларини кўзлаб моддий ёки номоддий наф олиш мақсадида қонунга хилоф  равишда фойдаланиши, худди шунингдек бундай нафни қонунга хилоф равишда тақдим этиши  тушунилади",
  kor26: "Коррупцияга қарши кураш тарихи",
  kor27: "Арасту",
  send1: "Юбориш",
  naming: "Ф.И.О.",
  pgone: "Телефон рақам",
  maill: "Электрон почта манзили",
  texx: "Хабар мазмуни",
  ukaz: "Норматив ҳуқуқий ҳужжатлар",
  kor28: "Ҳар қандай давлат тузумида энг муҳими – бу  қонунлар ва тартиб-қоидалар  воситасида ишни шундай  ташкил этиш керакки мансабдор  шахслар қинғир йўл билан бойлик орттира олмаси",
  kor29: "Цицерон",
  kor30: "У бой вилоятга камбағал бўлиб келиб, камбағал вилоятдан бой бўлиб кетди",
  kor31: "Эдмунд Бэрк",
  kor32: "К оррупция барқ ураётган жамиятда озодлик узоқ вақт ҳукм суриши мумкин эмас",
  kor33: "Питер Айген",
  kor34: "Коррупция – бу шахсий  наф кўриш йўлида  давлат ҳокимиятини  суиистеъмол қилиш",
  kor35: "Коррупциявий жиноятлар келиб чиқишининг бир неча омиллари мавжуд",
  kor36: "Антропологик омиллар",
  kor37: "Инсоннинг зиддиятли табиати, эзгулик ва ёвузликнинг доимий кураши, инсоннинг кам куч сарфлаб кўпроқ фойда олишга интилиши.",
  kor38: "Ижтимоий омиллар",
  kor39: "Жамиятнинг ижтимоий табақалашуви, “хавф гуруҳлари”нинг мавжудлиги, меҳнат қонунчилигининг бузилиши, муҳожир ишчилар, аёллар ва ўсмирлар меҳнатидан фойдаланишда ижтимоий адолат принципларига риоя этилмаслиги.",
  kor40: "Ҳуқуқий омиллар",
  kor41: "Тадбиркорликнинг ҳуқуқий асоси  номукаммаллиги, бозор  хўжалигининг ўзгариб борувчи шарт-шароитлари билан  мавжуд қонунчилик  базаси ўртасида зиддият мавжудлиги, уй хўжалигининг тартибга  солинмаслиги..",
  kor42: "Ахлоқий омиллар",
  kor43: "қонунчилик ҳамда  тадбиркорликнинг  ахлоқий асослари ўртасидаги зиддиятлар,  давлат ва жамият, давлат ва  индивид манфаатлари  ўртасидаги зиддиятлар;  миллий анъаналарнинг  ҳисобга олинмаслиги,  диний қоидаларнинг таъсири ва бошқалар.",
  kor44: "Антропологик омиллар",
  kor45: "Тадбиркорлик фаолиятининг юқори даражадаги  трансакциявий харажатлари (“қонунга  бўйсуниш баҳоси”), бозор хўжалиги доирасидаги  рақобат, иқтисодиётнинг  турли секторларининг  нотекис ривожланиши ва  бошқалар.",
  kor46: "Сиёсий омиллар",
  kor47: "ҳокимият ва йирик  капитал ўртасидаги ўзаро  муносабатлар олигархиянинг  шаклланиши, ҳокимият  кучи ҳамда иқтисодий  фаолиятга таъсир кўрсатишнинг кучга  асосланган усуллари  ўртасидаги нисбат.",
  kor48: "Маиший коррупция",
  kor49: "Асосан мансабдор шахслар  ва давлат хизматчиларининг  кундалик иш фаолиятида  фуқаролар билан  муносабатларида кенг  тарқалган",
  kor50: "Коррупциянинг бир нечта турлари мавжуд",
  kor51: "Ишбилармонлик  коррупцияси",
  kor52: "Асосан мансабдор шахслар  ва давлат хизматчиларининг  кундалик иш фаолиятида  фуқаролар билан  муносабатларида кенг  тарқалган",
  kor53: "Олий ҳокимият органлари коррупцияси",
  kor54: "Асосан мансабдор шахслар  ва давлат хизматчиларининг  кундалик иш фаолиятида  фуқаролар билан  муносабатларида кенг  тарқалган",
  kor55: "Коррупциявий жиноятларнинг содир этилишига шароит яратувчи омиллар, бу иллат устидан ташкилий-бошқарув ва ижтимоий назорат етарли эмаслиги ҳисобланади.",
  kor56: "Уларга қуйидагилар киради",
  kor57: "Режалаштиришдаги камчиликлар, шартнома талабларини иштирокчилар томонидан бузилиши, хизмат фаолиятини ташкил этишдаги нуқсонлар (мажбуриятларни тақсимлаш, ваколатни белгилаш,  иш хажмининг кўплиги ва ҳ.к.).",
  kor58: "Хўжасизлик ва ортиқча сарф-харажатга йўл қўйиб бериш (энергия манбаларидан, моддий ва одам  ресурсларидан тежамкорликсиз фойдаланиш), тарбиявий ишлардаги нуқсонлар.",
  kor59: "Ҳуқуқни муҳофаза қилувчи ва назорат органлари фаолиятидаги мансабдор шахслар,  хизматчиларнинг фойдаси ва сарф-ҳаражатлари ва улар томонидан хизмат вазифаларини бажариши  устидан назоратни йўқлиги, шунингдек, коррупциявий ҳолатларга эътиборсизлик.",
  kor60: "Кадрларни танлаш ва жой-жойига қўйишдаги камчиликлар (билимсиз ва тажрибаси йўқ ёки ишончсиз шахсларни қариндош уруғчилик, таниш-билишчилик асосида ишга қабул қилиш ва шунга  ўхшаш ҳолатлар).",
  kor61: "Ҳисоб-китоб ва назоратнинг сустлиги.",
  kor62: "Коррупциявий ҳолатларнинг пайдо бўлиш сабаблари",
  kor63: "Давлат секторида  ойлик маошларнинг  хусусий секторга  қараганда анча  пастлиги",
  kor64: "Мамлакат иқтисодиёти  асосан давлат томонидан  бошқарилиши",
  kor65: "Муайян хизмат  турларида давлатда  якка ҳукмронлик  ўрнатилгани",
  kor66: "Давлат бошқарув  органларининг халқ  билан алоқалари узилиб қолганлиги",
  kor67: "Иқтисодий  барқарорликнинг  мавжуд эмаслиги",
  kor68: "Халқнинг этник  жиҳатдан бирдам  эмаслиги",
  kor69: "Иқтисодий ривожланиш  (ЯИМнинг аҳоли жон бошига  ўсиши) суръатлари пастлиги",
  kor70: "Жамиятда  радикал-диний  қарашларнинг  мавжудлиги",
  kor71: "Иқтисодий ривожланиш  (ЯИМнинг аҳоли жон бошига  ўсиши) суръатлари пастлиги",
  kor72: "Жамиятнинг  ҳуқуқий маданияти  пастлиги",
  kor73: "Икки хил талқин  этиладиган қонун  нормаларининг  мавжудлиги",
  kor74: "Мамлакатдаги сиёсий беқарорлик",
  kor75: "Фуқароларнинг давлат  органлари фаолияти  устидан жамоатчилик  назорати сустлиги",
  kor76: "Жамият ва давлат  бошқарувида сиёсий ҳомийлик, маҳаллилийчилик, қариндош-уруғ  ва ошна-оғайнигарчилик  муносабатларининг юқорилиги",
  kor77: "Коррупция ва уюшган жиноятчиликка қарши курашиш,  шунингдек, ҳуқуқбузарликларнинг олдини олиш масалаларини  самарали ечиш устувор вазифаларимиздан бири бўлиб қолади",
  kor78: "Ўзбекистон Республикаси Президенти Шавкат Мирзиёев",
  kor79: "Ўзбекистон Республикаси коррупцияга қарши курашиш соҳасида халқаро  ҳамкорликни бир қатор халқаро ташкилотлар билан амалга оширади",
  kor80: "EGMONT GROUP (Молиявий разведкалар уюшмаси)",
  kor81: "UNODC (БМТнинг Наркотик ва жиноятчилик бўйича  идораси)",
  kor82: "Европа ИттифоқиUNDP (БМТ тараққиёт дастури)",
  kor83: "OSCE (Европада хавсизлик ва ҳамкорлик ташкилоти)",
  kor84: "OECD (Иқтисодий хамкорлик ва ривожланиш   ташкилоти)",
  kor85: "FATF (Ноқонуний даромадларни легаллаштиришга қарши ташкилоти)",
  kor86: "Ўзбекистон Республикасида коррупцияга қарши курашишга оид  давлат сиёсатининг устувор йўналишлари",
  kor87: "Ўзбекистон Республикасида коррупцияга қарши курашишга оид  давлат сиёсатининг устувор йўналишлари",
  kor88: "Тадбиркорликнинг ривожланиши учун ҳуқуқий чекловларни ва ташкилий тўсиқларни олиб  ташлаш",
  kor89: "Давлат хизматларини тақдим этиш  ва маъмурий тартиб-таомилларни  тартибга солишнинг ҳуқуқий ва институционал асосларини  такомиллаштириш",
  kor90: "Маъмурий ислоҳотларни амалга ошириш, давлат  органларининг вазифаларини такомиллаштириш,  давлатга тегишли бўлмаган функцияларни хусусий  секторга ўтказиш",
  kor91: "Коррупцияга қарши кураш соҳасида  суд ва ҳуқуқни мухофаза қилиш  органларининг мақсадлари,  вазифалари ва функцияларини такомиллаштириш",
  kor92: "Давлат органлари, жамоат  ва фуқаролик жамияти институтлари ўртасида алоқа тизимини йўлга қўйиш",
  kor93: "Кадрлар тайёрлаш, қайта тайёрлаш ва малакасини ошириш  тизимини такомиллаштириш,  юқори аҳлоқий меъёрларни  шакллантириш",
  kor94: "Коррупцияга қарши кураш соҳасида суд ва ҳуқуқни мухофаза қилиш органларининг мақсадлари, вазифалари ва функцияларини такомиллаштириш",
  kor95: "Коррупциявий жиноат ва ҳуқуқбузарликлар содир этилишини олдини олиш  мақсадида Ҳуқуқбузарликлар профилактикаси, Ҳуқуқий ахборотни тарқатиш  ва ундан фойдаланишни таъминлаш, Коррупцияга қарши курашиш, Судьялар олий кенгаши, Давлат харидлари, Жабрланувчиларни, гувоҳларни ва жиноят процессининг бошқа иштирокчиларини ҳимоя қилиш тўғрисидаги ва бир қатор бошқа конунлар қабул қилинди",
  kor96: "Ўзбекистон Республикасида коррупцияга қарши курашиш тизими ривожланиши:",
  kor97: "Коррупцияга оид ҳуқуқбузарликларни ўз вақтида аниқлаш, уларга  чек қўйиш, уларнинг оқибатларини, уларга имкон берувчи сабаблар  ва шарт-шароитларни бартараф этиш, коррупцияга оид  ҳуқуқбузарликларни содир этганлик учун жавобгарликнинг  муқаррарлиги принципини таъминлаш.",
  kor98: "Аҳолининг ҳуқуқий онги ва ҳуқуқий маданиятини юксалтириш,  жамиятда коррупцияга нисбатан муросасиз муносабатни  шакллантириш",
  kor99: "Давлат ва жамият ҳаётининг барча соҳаларида коррупциянинг олдини олишга доир чора-тадбирларни амалга ошириш;",
  kor100: "Коррупциявий ҳуқуқбузарликлар профилактикасини бевосита амалга оширувчи органлар ва муассасалар тизими",
  kor101: "Ички ишлар  органлари",
  kor102: "Давлат хавфсизлик хизмати органлари",
  kor103: "Давлат божхона  хизмати органлари",
  kor104: "Прокуратура органлари",
  kor105: "Адлия органлари",
  kor106: "Коррупцион фаолиятдан келадиган фойдани камайтиришга қаратилган  чораларга қуйидагиларни киритиш мумкин",
  kor107: "Давлат хизматчилари учун  меҳнатга адолатли ҳақ тўлаш  ва бошқа ижтимоий  манфаатдорлик тизимини  жорий этиш",
  kor108: "Ҳалолликни рағбатлантириш",
  kor109: "Коррупцияга қарши  қоидаларни бузганлик учун мансабдан бўшатишнинг  мажбурийлигини йўлга  қўйиш",
  kor110: "Мансаб бўйича  иммунитетни бекор  қилиш",
  kor111: "Мансаб ва лавозим  бўйича ўсишга адолатли  ва барча учун бир хил  шарт шароит ҳамда  имкон бериш",
  kor112: "Нафақага чиқиш учун  муносиб шароит  яратиш ва ҳ. к",
  kor113: "Марказ ва унинг ҳудудий филиалларининг ходимларига нисбатан коррупцияга оид ҳуқуқбузарлик ва коррупция аломатлари мавжуд бўлган маълумотларни, Марказнинг коррупцияга қарши курашиш ва комплаенс назоратига –<b> (93) 593 48 89;  (71) 203 00 22” (1019)</b> ёки бевосита Кадрлар бўлимига <b>93 386 20 10; “(71) 203 00 22” (1010)</b> шаҳар ва тезкор уяли алоқа телефонлари орқали берилиши мақсадга мувофиқ",
  rahbar1_7: "Умумий очиқ маълумотларни жойлаштиришгга маъсул шахслар",
  nav_1: `Мақсади ва йўналишлари`,
  nav_2: `Ҳуқуқий фаолият асослари`,
  nav_3: `Марказий аппарат ва таркибий бўлинма`,
  nav_4: `Марказ тарихи`,
  nav_5: `Раҳбарият`,
  nav_6: `Янгиликлар`,
  nav_7: `Ёшлар сиёсати`,
  nav_8: `Фаолият кўрсаткичлари`,
  nav_9: `Халқаро алоқалар`,
  nav_10: `Коррупцияга қарши кураш`,
  nav_11: `Функция ва вазифалар`,
  nav_12: `Ешлар сиесати`,
  nav_13: `Қонунчилик`,
  ish: "Иш вақти",
  dav1: "Давлат муассасаси",
  dav2: "Иш куни: ",
  dav3: "Душанба – Жума, 9:00 -18:00",
  dav4: "Дам олиш кунлари:",
  dav5: "Шанба, Якшанба",
  fot1: "Алоқа",
  dav6: "Тел: ",
  dav7: "Exat",
  dav8: "E-почта",
  nav_14: `Давлат хизматлари`,
  nav_15: `Очиқ маълумотлар`,
  nav_16: `Кўпинча сўраладиган саволлар ва жавоблар`,
  nav_17: `Хизматлар`,
  nav_18: `Молия тоғрисидаги очиқ маълумотлар`,
  nav_19: `Бўш иш ўринлари`,
  nav_20: `Умумий очиқ маълумотларни жойлаштиришгга маъсул шахслар`,
  nav_21: `Aхборот олишга доир сўровларни кўриб чиқиш`,
  nav_22: `Aхборот хизмати билан боғланиш`,
  nav_23: `Давлат екологик експертизаси хулосалари реестери`,
  nav_24: `Давлат органлари ва ташкилотларининг тасдиқланган ярим йиллик ва йиллик иш режалари`,
  nav_25: `Марказ фаолиятига оид ҳисоботлар`,
  nav_26: `Фуқороларнинг мурожатларини кўриб чиқиш натижалари ва мурожаат каналлари бўйича маълумот`,
  nav0: "Матбуот анжумани",
  nav_info_1: "Почта",
  nav_info_2: "Телеграм канал",
  nav_info_3: "Техник ёрдам гуруҳи",
  nav_info_4: "Давлат экологик экспертизасидан ўтказишга буюртма юбориш бўйича ЙЎРИҚНОМА",

  calculator: `Калькулятор`,

  vacancy_company: "Ташкилот",
  vacancy_location: "Манзил",

  nav_test: `Сайт тест режимида ишламоқда`,

  kr1: "Коррупция қарши курашиш",
  kr2: "Январ",
  kr3: "Давлат экологик экспертизаси маркази ходимларининг одоб-ахлоқ қоидалари",
  kr4: "Давлат экологик экспертизаси марказида коррупцияга қарши курашиш сиёсати тоғрисида низом",
  kr5: "Июль",
  kr6: "Юридик ва жисмоний шахслардан ижтимоий тармоқлар ва оммавий ахборот воситалари орқали келиб тушаётган мурожаатларни тезкорлик билан ўрганишни ташкил этиш тўғрисида",
  kr7: "Қисқа биография",
  kr8: "Вазифалар",
  kr10: "МEҲНАТ ФАОЛИЯТИ",
  kr9: " Мухамедов Ғайрат Аминович 1966 йил 19 февралда   Бухоро вилоятида туғилган. Маълумоти – олий, 1992 йилда Тошкент давлат аграр университетини ўрмон хўжалиги муҳандиси йўналишини, 2004 йилда Ўзбекистон Республикаси Президенти ҳузуридаги Давлат ва жамият қурилиши академиясини давлат ва жамият қурилиши магистри, 2005 йилда Бухоро давлат университети иқтисодиёт йўналишини тамомлаган.",
  kr11: "1983-2001 йй. – Қишлоқ ва ўрмон хўжалигида турли лавозимларда бошлаган.",
  kr12: "2002-2011 йй. - Тошкент вилояти «Угам-Чотқол» давлат табиат миллий боғи Оҳангарон ўрмон хўжалиги корхонаси директори лавозимида",
  kr13: "2001-2002 йй. - Бухоро вилояти ўрмон хўжалиги бошқармаси бошлиғи лавозимида",
  kr14: "2011-2015 йй. - Тошкент вилояти Табиатни муҳофаза қилиш қўмитаси раиси",
  kr15: "2015-2016 йй. - Ўзбекистон Республикаси Табиатни муҳофаза қилиш давлат қўмитаси Ҳайвонот ва ўсимлик дунёсини муҳофаза қилиш ва улардан оқилона фойдаланиш Республика давлат инспекцияси бошлиғи лавозимида",
  kr16: "2016-2021 йй. - “PETROMARUZ OVERSEAS” Британия компаниясининг Ўзбекистон бўйича ижрочи директори, бош директори лавозимида",
  kr17: "2021-2021 йй. - “SURHANCEMENTINVEST” хорижий корхонаси бош директори лавозимида",
  kr18: "2021-2022 йй. - “Ўзбекистон пахта-тўқимачилик кластерлари” уюшмаси раиси ўринбосари лавозимида",
  kr19: "2022 йй. - Ўзбекистон Республикаси Экология, атроф-муҳитни муҳофаза қилиш ва иқлим ўзгариши вазирлигининг “Давлат экологик экспертизаси маркази” давлат муассасаси Бош директори лавозимида фаолият олиб бормоқда.",
  kr20: "Aйтмуратов Тимурлан Парахатович 1991 йил 5 апрелда Қорақалпоғистон Республикаси, Чимбой туманида туғилган. Маълумоти – олий, 2013 йилда Тошкент ирригация ва қишлоқ хўжалигини механизациялаш муҳандислари институтини экология ва атроф муҳитни муҳофаза қилиш йўналишида, 2015 йилда Тошкент кимё-технология институтини (магистратура) ноорганика моддаларинг кимёвий технологияси йўналишини тамомлаган.",
  kr21: "2013-2017 йй. – Ўзбекистон Республикаси Экология ва атроф-муҳитни муҳофаза қилиш давлат қўмитаси ҳузуридаги “Давлат экологик сертификатлаштириш ва стандартлаштириш маркази” давлат унитар корхонаси етакчи, бош мутахассиси лавозимларида бошлаган.",
  kr22: "2017-2021 йй. –  Ўзбекистон Республикаси Экология ва атроф-муҳитни муҳофаза қилиш давлат қўмитаси ҳузуридаги “Давлат экологик сертификатлаштириш ва стандартлаштириш маркази” давлат унитар корхонаси директори лавозимида.",
  kr23: "2021-2022 йй. – Ўзбекистон Республикаси Экология ва атроф-муҳитни муҳофаза қилиш давлат қўмитаси ҳузуридаги “Тошкент шаҳар Давлат экологик экспертизаси маркази” давлат унитар корхонаси директори лавозимида.",
  kr24: "2022 й. -  ҳ.в.   - Ўзбекистон Республикаси Экология, атроф-муҳитни муҳофаза қилиш ва иқлим ўзгариши вазирлигининг “Давлат экологик экспертизаси маркази” давлат муассасаси Бош директорининг биринчи ўринбосари лавозимида фаолият олиб бормоқда.",
  kr25: "Ши-сянь Вячеслав Викторович 1982 йил 22 февралда Тошкент вилоятида туғилган. Маълумоти  олий – магистр, 2006 йилда Тошкент кимё-технология институтини атроф-муҳит муҳофазаси йўналишини тамомлаган.",
  kr26: "2003-2006 йй. - Тошкент вилояти табиатни муҳофаза қилиш қўмитаси инспектори лавозимида бошлаган.",
  kr27: "2006-2007 йй.  - Тошкент вилояти табиатни муҳофаза қилиш қўмитаси бўлим бошлиғи лавозимида",
  kr28: "2007-2008 йй. - “Tabiat Qurilish Loyiha” Маъсулияти чекланган жамият бош директори ўринбосари лавозимида",
  kr29: "2007-2008 йй. - “Ekoligiat-Norm” Маъсулияти чекланган жамият бош директори  лавозимида",
  kr30: "2012-2013 йй. - “BOSHDAVEKOEKSPERTIZA” DUK бош мутахасиси лавозимида",
  kr31: "2013-2014 йй. - “BOSHDAVEKOEKSPERTIZA” DUK бошлиқ ўринбосари лавозимида",
  kr32: "2014-2022 йй. -  “EKO PROM KONSALTING” маъсулияти чекланган жамият бош директори лавозимида",
  kr33: "2022 й. -  ҳ.в.   - Ўзбекистон Республикаси Экология, атроф-муҳитни муҳофаза қилиш ва иқлим ўзгариши вазирлигининг “Давлат экологик экспертизаси маркази” давлат муассасаси Бош директори ўринбосари лавозимида фаолият олиб бормоқда.",
  kr34: "Савол-жавоб",
  kr35: "Веб сайтимиз сизга йоқдими?",
  kr36: "Аъло",
  kr37: "Яхши",
  kr38: "Қониқарли",
  kr39: "Овоз бериш",
  kr40: "Жавоблар",

  rahbar_10: `Пресс релиз`,
  rahbar_11: `Матбуот анжумани`,
  rahbar_12: `Инфографика`,
  rahbar_13: `Фотогалерея`,
  rahbar_14: `Видеогалерея`,

  
  text_1: "Маълумот толдирилмоқда...",


  kar1: "Давлат экологик экспертизаси маркази Тошкент вилояти филиали",
  kar2: "Выбрать область",
  hamk: "Ҳамкорлар",
  kar3: "Тошкент шахри",
  kar4: "Тошкент вилояти",
  kar5: "Андижон вилояти",
  kar6: "Фарғона вилояти",
  kar7: "Наманган вилояти",
  kar8: "Сирдарье вилояти",
  kar9: "Жиззах вилояти",
  kar10: "Самарқанд вилояти",
  kar11: "Қашқадарё вилояти",
  kar12: "Сурхондарё вилояти",
  kar13: "Навоий вилояти",
  kar14: "Хоразм вилояти",
  kar15: "Қорақалпоғистон Республикаси",


};

export default translationsUzKril;