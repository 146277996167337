import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../GlobalContext'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { GET_LEGACY } from '../../../constants/urls'

const Legacy = () => {
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async () => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_LEGACY)}`)
    if (info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])

  console.log(data);
  return (
    <section className='AboutCommite'>
      <div className="containerr">
        <h2 className='commite-title'>{data[0]?.name}</h2>
        <img className='comite-img' src={data[0]?.image_main} alt="" />

        {data[0]?.legal_activity?.length ? data[0]?.legal_activity?.map((item, index) => (
          <div data-aos="fade-right" className="commite-info1" key={index}>
            <div className='commite-info1__info'>
              <p data-aos="fade-right" dangerouslySetInnerHTML={{ __html: item?.text }} />
            </div>
            <img data-aos="fade-left" src={item?.image} alt="" />
          </div>
        )) : ''}
      </div>
    </section>
  )
}

export default Legacy