import React, { useEffect } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useState } from 'react'
// import Pagoda from '../../../assets/images/icons/pagoda.svg'
// import Opros from '../../../assets/images/icons/opros.svg'
import Form from 'react-bootstrap/Form';
import video_eco from '../../../assets/xw4u5mo16f2skvel328x.mp4'
import { useTranslation } from 'react-i18next'

export default function Kalendar() {
  const [value, onChange] = useState(new Date());

  const { t } = useTranslation();

  return (
    <>
      <div className='video_home'>
        <div className="container">
          <div className="col-12">
            <div className="part_name_box">
              <div className="part_h_2">
                {t('nav_info_4')}
              </div>
            </div>
          </div>
          <div className="col-12">
            <video className='video_home_page' controls >
              <source src={video_eco} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  )
}
