const translationsUz = {
  home: "ASOSIY",
  about: "MARKAZ HAQIDA",
  activity: "FAOLIYAT",
  services: "Ochiq ma'lumotlar",
  services2: "Xizmatlar",
  contact: "Bog'lanish",
  herotittle: "DAVLAT EKOLOGIK EKSPERTIZASI MARKAZI",
  staff: "XODIMLAR",
  followUs: "Bizni ijtimoiy tarmoqlarda kuzatib boring",
  number: "Telefon raqam",
  address: "Yuridik manzil",
  email: "Elektron pochta",
  latestnews: "So'ngi yangiliklar",
  news: "Yangiliklar",
  documents: "Normativ huquqiy hujjatlar",
  hotlines: "Ishonch telefonlari",
  services_info: "sayt orqali ko'rsatiladigan interaktiv xizmatlar ro'yhati",
  see_all: "Hammasini ko'rish",
  send1: "Yuborish",
  more: "Batafsil",
  xizmat1:
    "Ijtimoiy sohani rivojlantirish davlat dasturlari, konsepsiyalari va sxemalari, shuningdek alohida muhofaza etiladigan tabiiy hududlarni boshqarish rejalari loyihalarini davlat ekologik ekspertizasidan o‘tkazish.",
  xizmat2:
    "Ishlab chiqarish kuchlari loyihalari, iqtisodiyot tarmoqlarini rivojlantirish konsepsiyalari va davlat ekologik ekspertizasidan o‘tkazish",
  xizmat3:
    "Barcha turdagi qurilish uchun yer uchastkalarini tanlash uchun materiallarni davlat ekologik ekspertizasidan o'tkazish",
  xizmat4:
    "Faoliyat turlari bo'yicha layihaoldi va loyiha xujjatlarini davlat ekologik ekspertizasidan o'tkazish",
  xizmat5:
    "Tabiy resurslardan foydalanish bilan bog'liq xo'jalik va boshqa faoliyatni tartibga soluvchi normotiv-texnik va yo'riqnoma uslubiy xujjatlarning loyihalarini davlat ekologik ekspertizasidan o'tkazish",
  xizmat6:
    "Texnika, texnalogiya, materiallar, moddalar maxsulotlarning yangi turlarini yaratishga doir xujjatlarni davlat ekologik ekspertizasidan o'tkazish",
  xizmat7:
    "Atrof ta'biy muxitga va fuqoralarning sog'lig'iga salbiy ta'sir ko'rsatuvchi ishlab turgan korxonalar va boshqa obyektlarni davlat ekologik ekspertizasidan o'tkazish",
  xizmat8:
    "Xududlarga keyinchalik muxofaza qilinadigan ta'biy xududlar, favqulodda ekologik vaziyat va ekologik ofat zonalari maqomini berish maqsadida ularni kompleks tekshirish materiallarini davlat ekologik ekspertizasidan o'tkazish",
  xizmat9:
    "Shaxarsozlik xujjatlarini davlat ekologik ekspertizasidan o'tkazish",
  connect_span: "Biz",
  connect: "bilan aloqa",
  naming: "F.I.SH.",
  pgone: "Telefon raqam",
  maill: "Elektron pochta manzili",
  texx: "Xabar mazmuni",
  ukaz: "Normativ huquqiy hujjatlar",
  region_tittle:
    "O‘zbekiston Respublikasi Ekologiya va atrof-muhitni muhofaza qilish davlat qo‘mitasi “Ekologik ekspertiza markazi” Qoraqalpog‘iston Respublikasi, viloyatlar va Toshkent shahar bo‘limi xodimlari to‘g‘risida MA’LUMOTLAR",
  user_data: "Tug'ilgan joyi va vaqti",
  fio: "F.I.SH",
  user_tell: "Telefon raqami",
  appointment: "Lavozimi",
  education: "Ma'lumoti va mutaxasisligi",
  language: "../assets/images/flags/uz.jpg",
  list: "Batafsil",
  location: "Toshkent shahar, Mirzo Ulug`bek tumani, Sayram kuchasi, 15-uy",
  ish:"Ish Vaqti",

  rahbar4_4: "Markaziy Apparat",
  rahbar1_1: "Markaz filiallari rahbarlari",
  rahbar1: "Davlat ekologik ekspertiza markazi rahbariyati",
  rahbar2: "F.I.Sh.",
  rahbar3: "Qabul quyidagi manzil bo‘yicha amalga oshiriladi",
  rahbar4: "Telefon raqam",
  rahbar5: "Lavozimi ",
  rahbar6: " E-mail manzili",
  nav_19_1: " Xalqaro aloqalar",
  rahbar8: " Qabul vaqtlari",

  rahbar_2:`Xulosalar`,
  rahbar_3:`Loyihachilar reytingi`,
  rahbar_4:`Axborot xizmati`,
  rahbar_5:`Jamoatchilik eshtuvi`,
  dav1: "Davlat muassasasi",
  dav2: "Ish kuni: ",
  dav3: "Dushanba – Juma, 9:00 -18:00",
  dav4: "Dam olish kuni:",
  dav5: "Shanba, Yakshanba",

  dav6: "Tel: ",
  dav7: "Exat",
  dav8: "E-pochta",
  rahbar_10:`Press reliz`,
  rahbar_11:`Matbuot anjumani`,
  rahbar_12:`Infografika`,
  rahbar_13:`Fotogalereya`,
  rahbar_14:`Videogalereya`,

  text_1: "Ma'lumot toldirilmoqda...",

  // korrupsion

  kor1: "O‘ZBEKISTON  RESPUBLIKASI EKOLOGIYA VA ATROF-MUHITNI MUHOFAZA QILISH DAVLAT QO‘MITASINING DAVLAT EKOLOGIK EKSPERTIZASI MARKAZI “KORRUPTSIYAGA QARSHI KURASHISH BORASIDAGI ASOSIY YO‘NALISHLAR“",
  kor2: "Kirish",
  kor3: "Korruptsiyani	belgilovchi omillar	va	uning	kelib	chiqish  sabablari",
  kor4: "Korruptsiyaga qarshi kurash borasida davlat siyosatining asosiy  yo‘nalishlari",
  kor5: "O‘zbekiston	Respublikasining	korruptsiyaga	qarshi  kurashish tizimi rivojlanishi",
  kor6: "Korruptsiya",
  kor7: "Korruptsiya (lotincha:  corruptio — aynish, poraga sotilish)  shaxsning o‘z mansab yoki xizmat mavqeyidan shaxsiy manfaatlarini yohud o‘zga shaxslarning manfaatlarini ko‘zlab moddiy yoki nomoddiy naf olish maqsadida qonunga xilof ravishda foydalanishi, huddi shuningdek bunday nafni qonunga xilof ravishda taqdim etish,  mansabdor shaxslarni sotib olish, ularning poraga sotilishi; saylanadigan, tayinlanadigan lavozimlarning sotilishi;",
  kor8: "Lobbizm (monopoliyalarning qonunchilik organlari va amaldorlarga tazyiq o‘tkazish bilan shug‘ullanadigan muassasalar tizimi). Xalqaro darajada 1970-yillarda Yaponiyaga samolyotlar sotishda kompaniya tomonidan oliy davlat amaldorlarini sotib olish bo‘yicha Loktsid ishi eng yirik korruptsiya ko‘rinishlariga misol bo‘ladi. Porani haspo‘shlash uchun yirik kelishuvlarda ko‘proq xizmat haqi to‘lash amaliyotidan foydalaniladi.",
  kor9: "MANFAATLAR TO‘QNASHUVINING KO‘RINIShLARI",
  kor10: "Lobbizm (ingliz. lobby – kuluar, koridor)",
  kor11:
    "norasmiy shaxslarning o‘zaro  uchrashib, rasmiy qarorlar qabul  qilishdagi siyosiy ta'sirning  o‘ziga xos turi",
  kor12: "Rahnamolik, favoritizm  (fr. favorit)",
  kor13: "ximoya ostidagi ayrim  xodimlarni yaqin olish va  qullab-quvvatlash",
  kor14: "Protektsionizm (lot. ko‘maklashish)",
  kor15: "ma'lum bir shaxslarni lavozimga  tayinlashga ko‘maklashish",
  kor16: "Nepotizm (lot. nepos jiyan)",
  kor17:
    "etnik printsiplar asosida  davlat tuzilmalarida yoki boshqa tuzilmalarda tor guruhchilik  manfaatlari bilan ish ko‘rish",
  kor18: "Kliyentelizm (ingl. clientelism,  lot. clientēla - mijoz)",
  kor19:
    "lavozimdagi mansabdor shaxs va unga xayrixox bo‘lgan shaxslar (mansabdor shaxslar,  tadbirkorlar, siyosiy guruhlar)",
  kor20: "Kronizm	(ingliz.	xamfikr, tanish bilichilik, guruhbozlik)",
  kor21: "lavozimlarni tanish-bilishchilik	asosida berish",
  kor22: "Korruptsiyani belgilovchi omillar va uning kelib chiqish sabablari",
  kor23:
    "Korruptsiyaga oid  huquqbuzarlik - korruptsiya  alomatlariga ega bo‘lgan, sodir etilganligi uchun  qonun hujjatlarida  javobgarlik nazarda  tutilgan qilmishi  tushuniladi",
  kor24:
    "Korruptsiya atamasi buzish, tizimni izdan  chiqarish, pora evaziga  og‘dirish  degan  ma'noni anglatadigan  lotincha corruptio so‘zidan kelib chiqqan",
  kor25:
    "Korruptsiya — shaxsning o‘z mansab yoki xizmat mavqeidan shaxsiy manfaatlarini yoxud o‘zga  shaxslarning manfaatlarini ko‘zlab moddiy yoki nomoddiy naf olish maqsadida qonunga xilof  ravishda foydalanishi, xuddi shuningdek bunday nafni qonunga xilof ravishda taqdim etishi  tushuniladi",
  kor26: "Korruptsiyaga qarshi kurash tarixi",
  kor27: "Arastu",
  kor28:
    "Har qanday davlat tuzumida eng muhimi – bu  qonunlar va tartib-qoidalar  vositasida ishni shunday  tashkil etish kerakki mansabdor  shaxslar qing‘ir yo‘l bilan boylik orttira olmasi",
  kor29: "Sitseron",
  kor30:
    "U boy viloyatga kambag‘al bo‘lib kelib, kambag‘al viloyatdan boy bo‘lib ketdi",
  kor31: "Edmund Berk",
  kor32:
    "Korruptsiya barq urayotgan jamiyatda ozodlik uzoq vaqt hukm surishi mumkin emas",
  kor33: "Piter Aygen",
  kor34:
    "Korruptsiya – bu shaxsiy  naf ko‘rish yo‘lida  davlat hokimiyatini  suiiste'mol qilish",
  kor35:
    "Korruptsiyaviy jinoyatlar kelib chiqishining bir necha omillari mavjud",
  kor36: "Antropologik omillar",
  kor37:
    "Insonning ziddiyatli tabiati, ezgulik va yovuzlikning doimiy kurashi, insonning kam kuch sarflab ko‘proq foyda olishga intilishi.",
  kor38: "Ijtimoiy omillar",
  kor39:
    "Jamiyatning ijtimoiy tabaqalashuvi, “xavf guruhlari”ning mavjudligi, mehnat qonunchiligining buzilishi, muhojir ishchilar, ayollar va o‘smirlar mehnatidan foydalanishda ijtimoiy adolat printsiplariga rioya etilmasligi.",
  kor40: "Huquqiy omillar",
  kor41:
    "Tadbirkorlikning huquqiy asosi  nomukammalligi, bozor  xo‘jaligining o‘zgarib boruvchi shart-sharoitlari bilan  mavjud qonunchilik  bazasi o‘rtasida ziddiyat mavjudligi, uy xo‘jaligining tartibga  solinmasligi..",
  kor42: "Axloqiy omillar",
  kor43:
    "qonunchilik hamda  tadbirkorlikning  axloqiy asoslari o‘rtasidagi ziddiyatlar,  davlat va jamiyat, davlat va  individ manfaatlari  o‘rtasidagi ziddiyatlar;  milliy an'analarning  hisobga olinmasligi,  diniy qoidalarning ta'siri va boshqalar.",
  kor44: "Antropologik omillar",
  kor45:
    "Tadbirkorlik faoliyatining yuqori darajadagi  transaktsiyaviy xarajatlari (“qonunga  bo‘ysunish bahosi”), bozor xo‘jaligi doirasidagi  raqobat, iqtisodiyotning  turli sektorlarining  notekis rivojlanishi va  boshqalar.",
  kor46: "Siyosiy omillar",
  kor47:
    "hokimiyat va yirik  kapital o‘rtasidagi o‘zaro  munosabatlar oligarxiyaning  shakllanishi, hokimiyat  kuchi hamda iqtisodiy  faoliyatga ta'sir ko‘rsatishning kuchga  asoslangan usullari  o‘rtasidagi nisbat.",
  kor48: "Maishiy korruptsiya",
  kor49:
    "Asosan mansabdor shaxslar  va davlat xizmatchilarining  kundalik ish faoliyatida  fuqarolar bilan  munosabatlarida keng  tarqalgan",
  kor50: "Korruptsiyaning bir nechta turlari mavjud",
  kor51: "Ishbilarmonlik  korruptsiyasi",
  kor52:
    "Asosan mansabdor shaxslar  va davlat xizmatchilarining  kundalik ish faoliyatida  fuqarolar bilan  munosabatlarida keng  tarqalgan",
  kor53: "Oliy hokimiyat organlari korruptsiyasi",
  kor54:
    "Asosan mansabdor shaxslar  va davlat xizmatchilarining  kundalik ish faoliyatida  fuqarolar bilan  munosabatlarida keng  tarqalgan",
  kor55:
    "Korruptsiyaviy jinoyatlarning sodir etilishiga sharoit yaratuvchi omillar, bu illat ustidan tashkiliy-boshqaruv va ijtimoiy nazorat yetarli emasligi hisoblanadi.",
  kor56: "Ularga quyidagilar kiradi",
  kor57:
    "Rejalashtirishdagi kamchiliklar, shartnoma talablarini ishtirokchilar tomonidan buzilishi, xizmat faoliyatini tashkil etishdagi nuqsonlar (majburiyatlarni taqsimlash, vakolatni belgilash,  ish xajmining ko‘pligi va h.k.).",
  kor58:
    "Xo‘jasizlik va ortiqcha sarf-xarajatga yo‘l qo‘yib berish (energiya manbalaridan, moddiy va odam  resurslaridan tejamkorliksiz foydalanish), tarbiyaviy ishlardagi nuqsonlar.",
  kor59:
    "Huquqni muhofaza qiluvchi va nazorat organlari faoliyatidagi mansabdor shaxslar,  xizmatchilarning foydasi va sarf-harajatlari va ular tomonidan xizmat vazifalarini bajarishi  ustidan nazoratni yo‘qligi, shuningdek, korruptsiyaviy holatlarga e'tiborsizlik.",
  kor60:
    "Kadrlarni tanlash va joy-joyiga qo‘yishdagi kamchiliklar (bilimsiz va tajribasi yo‘q yoki ishonchsiz shaxslarni qarindosh urug‘chilik, tanish-bilishchilik asosida ishga qabul qilish va shunga  o‘xshash holatlar).",
  kor61: "Hisob-kitob va nazoratning sustligi.",
  kor62: "Korruptsiyaviy holatlarning paydo bo‘lish sabablari",
  kor63:
    "Davlat sektorida  oylik maoshlarning  xususiy sektorga  qaraganda ancha  pastligi",
  kor64: "Mamlakat iqtisodiyoti  asosan davlat tomonidan  boshqarilishi",
  kor65: "Muayyan xizmat  turlarida davlatda  yakka hukmronlik  o‘rnatilgani",
  kor66:
    "Davlat boshqaruv  organlarining xalq  bilan aloqalari uzilib qolganligi",
  kor67: "Iqtisodiy  barqarorlikning  mavjud emasligi",
  kor68: "Xalqning etnik  jihatdan birdam  emasligi",
  kor69:
    "Iqtisodiy rivojlanish  (YaIMning aholi jon boshiga  o‘sishi) sur'atlari pastligi",
  kor70: "Jamiyatda  radikal-diniy  qarashlarning  mavjudligi",
  kor71:
    "Iqtisodiy rivojlanish  (YaIMning aholi jon boshiga  o‘sishi) sur'atlari pastligi",
  kor72: "Jamiyatning  huquqiy madaniyati  pastligi",
  kor73: "Ikki xil talqin  etiladigan qonun  normalarining  mavjudligi",
  kor74: "Mamlakatdagi siyosiy beqarorlik",
  kor75:
    "Fuqarolarning davlat  organlari faoliyati  ustidan jamoatchilik  nazorati sustligi",
  kor76:
    "Jamiyat va davlat  boshqaruvida siyosiy homiylik, mahalliliychilik, qarindosh-urug‘  va oshna-og‘aynigarchilik  munosabatlarining yuqoriligi",
  kor77:
    "Korruptsiya va uyushgan jinoyatchilikka qarshi kurashish,  shuningdek, huquqbuzarliklarning oldini olish masalalarini  samarali yechish ustuvor vazifalarimizdan biri bo‘lib qoladi",
  kor78: "O‘zbekiston Respublikasi Prezidenti Shavkat Mirziyoyev",
  kor79:
    "Ўзбекистон Республикаси коррупцияга қарши курашиш соҳасида халқаро  ҳамкорликни бир қатор халқаро ташкилотлар билан амалга оширади",
  kor80: "EGMONT GROUP (Moliyaviy razvedkalar uyushmasi)",
  kor81: "UNODC (BMTning Narkotik va jinoyatchilik bo‘yicha  idorasi)",
  kor82: "Yevropa IttifoqiUNDP (BMT taraqqiyot dasturi)",
  kor83: "OSCE (Yevropada xavsizlik va hamkorlik tashkiloti)",
  kor84: "OECD (Иқтисодий хамкорлик ва ривожланиш   ташкилоти)",
  kor85: "FATF (Noqonuniy daromadlarni legallashtirishga qarshi tashkiloti)",
  kor86:
    "O‘zbekiston Respublikasida korruptsiyaga qarshi kurashishga oid  davlat siyosatining ustuvor yo‘nalishlari",
  kor87:
    "O‘zbekiston Respublikasida korruptsiyaga qarshi kurashishga oid  davlat siyosatining ustuvor yo‘nalishlari",
  kor88:
    "Tadbirkorlikning rivojlanishi uchun huquqiy cheklovlarni va tashkiliy to‘siqlarni olib  tashlash",
  kor89:
    "Davlat xizmatlarini taqdim etish  va ma'muriy tartib-taomillarni  tartibga solishning huquqiy va institutsional asoslarini  takomillashtirish",
  kor90:
    "Ma'muriy islohotlarni amalga oshirish, davlat  organlarining vazifalarini takomillashtirish,  davlatga tegishli bo‘lmagan funktsiyalarni xususiy  sektorga o‘tkazish",
  kor91:
    "Korruptsiyaga qarshi kurash sohasida  sud va huquqni muxofaza qilish  organlarining maqsadlari,  vazifalari va funktsiyalarini takomillashtirish",
  kor92:
    "Davlat organlari, jamoat  va fuqarolik jamiyati institutlari o‘rtasida aloqa tizimini yo‘lga qo‘yish",
  kor93:
    "Kadrlar tayyorlash, qayta tayyorlash va malakasini oshirish  tizimini takomillashtirish,  yuqori ahloqiy me'yorlarni  shakllantirish",
  kor94:
    "Korruptsiyaga qarshi kurash sohasida sud va huquqni muxofaza qilish organlarining maqsadlari, vazifalari va funktsiyalarini takomillashtirish",
  kor95:
    "Korruptsiyaviy jinoat va huquqbuzarliklar sodir etilishini oldini olish  maqsadida Huquqbuzarliklar profilaktikasi, Huquqiy axborotni tarqatish  va undan foydalanishni ta'minlash, Korruptsiyaga qarshi kurashish, Sudьyalar oliy kengashi, Davlat xaridlari, Jabrlanuvchilarni, guvohlarni va jinoyat protsessining boshqa ishtirokchilarini himoya qilish to‘g‘risidagi va bir qator boshqa konunlar qabul qilindi",
  kor96:
    "O‘zbekiston Respublikasida korruptsiyaga qarshi kurashish tizimi rivojlanishi:",
  kor97:
    "Korruptsiyaga oid huquqbuzarliklarni o‘z vaqtida aniqlash, ularga  chek qo‘yish, ularning oqibatlarini, ularga imkon beruvchi sabablar  va shart-sharoitlarni bartaraf etish, korruptsiyaga oid  huquqbuzarliklarni sodir etganlik uchun javobgarlikning  muqarrarligi printsipini ta'minlash.",
  kor98:
    "Aholining huquqiy ongi va huquqiy madaniyatini yuksaltirish,  jamiyatda korruptsiyaga nisbatan murosasiz munosabatni  shakllantirish",
  kor99:
    "Davlat va jamiyat hayotining barcha sohalarida korruptsiyaning oldini olishga doir chora-tadbirlarni amalga oshirish;",
  kor100:
    "Korruptsiyaviy huquqbuzarliklar profilaktikasini bevosita amalga oshiruvchi organlar va muassasalar tizimi",
  kor101: "Ichki ishlar  organlari",
  kor102: "Davlat xavfsizlik xizmati organlari",
  kor103: "Davlat bojxona  xizmati organlari",
  kor104: "Prokuratura organlari",
  kor105: "Adliya organlari",
  kor106:
    "Korruptsion faoliyatdan keladigan foydani kamaytirishga qaratilgan  choralarga quyidagilarni kiritish mumkin",
  kor107:
    "Davlat xizmatchilari uchun  mehnatga adolatli haq to‘lash  va boshqa ijtimoiy  manfaatdorlik tizimini  joriy etish",
  kor108: "Halollikni rag‘batlantirish",
  kor109:
    "Korruptsiyaga qarshi  qoidalarni buzganlik uchun mansabdan bo‘shatishning  majburiyligini yo‘lga  qo‘yish",
  kor110: "Mansab bo‘yicha  immunitetni bekor  qilish",
  kor111:
    "Mansab va lavozim  bo‘yicha o‘sishga adolatli nav_19 va barcha uchun bir xil  shart sharoit hamda  imkon berish",
  kor112: "Nafaqaga chiqish uchun  munosib sharoit  yaratish va h. k",
  kor113:
    "Markaz va uning hududiy filiallarining xodimlariga nisbatan korruptsiyaga oid huquqbuzarlik va korruptsiya alomatlari mavjud bo‘lgan ma'lumotlarni, Markazning korruptsiyaga qarshi kurashish va komplayens nazoratiga – (93) 593 48 89;  (71) 203 00 22” (1019) yoki bevosita Kadrlar bo‘limiga 93 386 20 10; “(71) 203 00 22” (1010) shahar va tezkor uyali aloqa telefonlari orqali berilishi maqsadga muvofiq",
  kor114: "",

  rahbar1_7: "Umumiy ochiq ma'lumotlarni joylashtirishgga ma'sul shaxslar",
  fot1: "Aloqa",
  

  nav_3_2: `Hududiy filiallar `,
  nav_4_2: `Qonunchilik xujjatlari`,
  nav_5_2: `Ochiq ma'lumotlar`,



  nav0: "Matbuot anjumani",
  nav_1: `Markaz haqida`,
  nav_1_2: `Tashkiliy tuzilma`,
  nav_2: `Huquqiy faoliyat asoslari`,
  nav_3: `Markaziy apparat `,
  nav_4: `Markaz tarixi`,
  nav_5: `Rahbariyat`,
  nav_6: `Yangiliklar`,
  nav_7: `Yoshlar siyosati`,
  nav_8: `Faoliyat ko'rsatkichlari`,
  nav_9: `Xalqaro aloqalar`,
  nav_10: `Korrupsiyaga qarshi kurash`,
  nav_11: `Markaz vazifalari va funksiyalari`,
  nav_12: `Yoshlar siyosati`,
  nav_13: `Qonunchilik`,
  nav_14: `Davlat xizmatlari`,
  nav_15: `Ochiq ma'lumotlar`,
  nav_16: `Ko'pincha so'raladigan savollar va javoblar`,
  nav_17: `Xizmatlar`,
  nav_18: `Moliya tog'risidagi ochiq ma'lumotlar`,
  nav_19: `Bo'sh ish o'rinlari`,
  nav_20: `Umumiy ochiq ma'lumotlarni joylashtirishgga ma'sul shaxslar`,
  nav_21: `Axborot olishga doir so'rovlarni ko'rib chiqish`,
  nav_22: `Axborot xizmati bilan bog'lanish`,
  nav_23: `Davlat ekologik ekspertizasi xulosalari reesteri`,
  nav_24: `Davlat organlari va tashkilotlarining tasdiqlangan yarim yillik va yillik ish rejalari`,
  nav_25: `Markaz faoliyatiga oid hisobotlar`,
  nav_26: `Fuqorolarning murojatlarini ko'rib chiqish natijalari va murojaat kanallari bo'yicha ma'lumot`,

  calculator: `Kalkulyator`,

  vacancy_company: "Tashkilot",
  vacancy_location: "Manzil",

  nav_info_1: "Pochta",
  nav_info_2: "Telegram kanal",
  nav_info_3: "Texnik yordam guruhi",
  nav_info_4: "Davlat ekologik ekspertizasidan oʼtkazishga buyurtma yuborish boʼyicha YOʼRIQNOMА",

  
  nav_test: `Sayt test rejimida ishlamoqda`,


    kr1: "Korrupsiya qarshi kurashish  ",
    kr2: "Yanvar",
    kr3: "Davlat ekologik ekspertizasi markazi xodimlarining odob-axloq qoidalari",
    kr4: "Davlat ekologik ekspertizasi markazida korrupsiyaga qarshi kurashish siyosati tog'risida nizom",
    kr5: "Iyul",
    kr6: "Yuridik va jismoniy shaxslardan ijtimoiy tarmoqlar va ommaviy axborot vositalari orqali kelib tushayotgan murojaatlarni tezkorlik bilan o'rganishni tashkil etish to'g'risida",
    kr7: "Qisqa biografiya",
    kr8: "Vazifalar",
    kr10: "MEHNAT FAOLIYATI",
    kr9: " Muxamedov G‘ayrat Aminovich 1966 yil 19 fevralda   Buxoro viloyatida tug‘ilgan. Ma’lumoti – oliy, 1992 yilda Toshkent davlat agrar universitetini o‘rmon xo‘jaligi muhandisi yo‘nalishini, 2004 yilda O‘zbekiston Respublikasi Prezidenti huzuridagi Davlat va jamiyat qurilishi akademiyasini davlat va jamiyat qurilishi magistri, 2005 yilda Buxoro davlat universiteti iqtisodiyot yo‘nalishini tamomlagan.",
    kr11: "1983-2001 yy. – Qishloq va o‘rmon xo‘jaligida turli lavozimlarda boshlagan.",
    kr12: "2002-2011 yy. - Toshkent viloyati “Ugam-Chotqol” davlat tabiat milliy bog‘i Ohangaron o‘rmon xo‘jaligi korxonasi direktori lavozimida",
    kr13: "2001-2002 yy. - Buxoro viloyati o‘rmon xo‘jaligi boshqarmasi boshlig‘i lavozimida",
    kr14: "2011-2015 yy. - Toshkent viloyati Tabiatni muhofaza qilish qo‘mitasi raisi",
    kr15: "2015-2016 yy. - O‘zbekiston Respublikasi Tabiatni muhofaza qilish davlat qo‘mitasi Hayvonot va o‘simlik dunyosini muhofaza qilish va ulardan oqilona foydalanish Respublika davlat inspeksiyasi boshlig‘i lavozimida",
    kr16: "2016-2021 yy. - “PETROMARUZ OVERSEAS” Britaniya kompaniyasining O‘zbekiston bo‘yicha ijrochi direktori, bosh direktori lavozimida",
    kr17: "2021-2021 yy. - “SURHANCEMENTINVEST” xorijiy korxonasi bosh direktori lavozimida",
    kr18: "2021-2022 yy. - “O‘zbekiston paxta-to‘qimachilik klasterlari” uyushmasi raisi o‘rinbosari lavozimida",
    kr19: "2022 y. -  h.v.   - O‘zbekiston Respublikasi Ekologiya, atrof-muhitni muhofaza qilish va iqlim o‘zgarishi vazirligining “Davlat ekologik ekspertizasi markazi” davlat muassasasi Bosh direktori lavozimida faoliyat olib bormoqda.",
    kr20: "Aytmuratov Timurlan Paraxatovich 1991 yil 5 aprelda Qoraqalpog‘iston Respublikasi, Chimboy tumanida tug‘ilgan. Maʼlumoti – oliy, 2013 yilda Toshkent irrigatsiya va qishloq xo‘jaligini mexanizatsiyalash muhandislari institutini ekologiya va atrof muhitni muhofaza qilish yo‘nalishida, 2015 yilda Toshkent kimyo-texnologiya institutini (magistratura) noorganika moddalaring kimyoviy texnologiyasi yo‘nalishini tamomlagan.",
  kr21: "2013-2017 yy. – O‘zbekiston Respublikasi Ekologiya va atrof-muhitni muhofaza qilish davlat qo‘mitasi huzuridagi “Davlat ekologik sertifikatlashtirish va standartlashtirish markazi” davlat unitar korxonasi yetakchi, bosh mutaxassisi lavozimlarida boshlagan.",
kr22: "2017-2021 yy. –  O‘zbekiston Respublikasi Ekologiya va atrof-muhitni muhofaza qilish davlat qo‘mitasi huzuridagi “Davlat ekologik sertifikatlashtirish va standartlashtirish markazi” davlat unitar korxonasi direktori lavozimida.",
kr23: "2021-2022 yy. – O‘zbekiston Respublikasi Ekologiya va atrof-muhitni muhofaza qilish davlat qo‘mitasi huzuridagi “Toshkent shahar Davlat ekologik ekspertizasi markazi” davlat unitar korxonasi direktori lavozimida.",
kr24: "2022 y. -  h.v.   - O‘zbekiston Respublikasi Ekologiya, atrof-muhitni muhofaza qilish va iqlim o‘zgarishi vazirligining “Davlat ekologik ekspertizasi markazi” davlat muassasasi Bosh direktorining birinchi o‘rinbosari lavozimida faoliyat olib bormoqda.",
kr25: "Shi-syan Vyacheslav Viktorovich 1982 yil 22 fevralda Toshkent viloyatida tug‘ilgan. Maʼlumoti  oliy – magistr, 2006 yilda Toshkent kimyo-texnologiya institutini atrof-muhit muhofazasi yo‘nalishini tamomlagan.",
kr26: "2003-2006 yy. - Toshkent viloyati tabiatni muhofaza qilish qo‘mitasi inspektori lavozimida boshlagan.",
kr27: "2006-2007 yy.  - Toshkent viloyati tabiatni muhofaza qilish qo‘mitasi bo‘lim boshlig‘i lavozimida",
kr28: "2007-2008 yy. - “Tabiat Qurilish Loyiha” Maʼsuliyati cheklangan jamiyat bosh direktori o‘rinbosari lavozimida",
kr29: "2007-2008 yy. - “Ekoligiat-Norm” Maʼsuliyati cheklangan jamiyat bosh direktori  lavozimida",
kr30: "2012-2013 yy. - “BOSHDAVEKOEKSPERTIZA” DUK bosh mutaxasisi lavozimida",
kr31: " 2013-2014 yy. - “BOSHDAVEKOEKSPERTIZA” DUK boshliq o‘rinbosari lavozimida",
kr32: "2014-2022 yy. -  “EKO PROM KONSALTING” maʼsuliyati cheklangan jamiyat bosh direktori lavozimida",
kr33: "2022 y. -  h.v.   - O‘zbekiston Respublikasi Ekologiya, atrof-muhitni muhofaza qilish va iqlim o‘zgarishi vazirligining “Davlat ekologik ekspertizasi markazi” davlat muassasasi Bosh direktori o‘rinbosari lavozimida faoliyat olib bormoqda.",



kr34: "Savol-Javob",
kr35: "Veb saytimiz sizga yoqdimi?",
kr36: "Alo",
kr37: "Yahshi",
kr38: "Qoniqarli",
kr39: "Ovoz berish",
kr40: "Javoblar",
hamk: "Hamkorlar",
kar1: "Davlat ekologik ekspertizasi markazi Toshkent viloyati filiali",
kar2: "Hududni tanlang",
kar3: "Toshkent shahri",
kar4: "Toshkent viloyati",
kar5: "Andijon viloyati",
kar6: "Farg'ona viloyati",
kar7: "Namangan viloyati",
kar8: "Sirdaryo viloyati",
kar9: "Jizzah viloyati",
kar10: "Samarqand viloyati",
kar11: "Qashqadaryo viloyati",
kar12: "Surxandaryo viloyati",
kar13: "Navoiy viloyati",
kar14: "Xorazm viloyati",
kar15: "Qoraqalpog'iston Respublikasi",


  };

export default translationsUz;
