import React from "react";
import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home/Home";
import AboutCommitee from "./components/pages/AboutCommittee/AboutCommitee";
import Activity from "./components/pages/Activity/Activity";
import Contact from "./components/pages/Contacts/Contact";
import News from "./components/pages/Home/MoreNews";
import Moretempus from "./components/pages/Home/MoreTempus";
import ReadMoreNews from "./components/pages/Home/ReadMoreNews";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsUz from "./locale/Uz";
import translationsRu from "./locale/Ru";
import translationsUzKril from "./locale/UzKril";
import translationsEn from "./locale/En";
import Staff from "./components/pages/staff/Staff";
import i18next from "i18next";
import Leftbg from "./assets/images/icons/naqshleft.svg";
import Rightbg from "./assets/images/icons/naqshright.svg";
import Ekoservice from "./assets/images/icons/eko-service.svg";
import Kalkulator from "./assets/images/icons/kalkulator.svg";
import Documents from "./components/pages/Documents/Documents";
import Andijon from "./components/pages/YoymaRoyxat/Andijon";
import Button from "react-bootstrap/Button";

import MyVerticallyCenteredModal from "./components/Modal/MyVerticallyCenteredModal";
import Markaztarixi from "./components/pages/Home/Markaztarixi";
import Korrupsion from "./components/pages/Korrupsion/Korrupsion";
import Rahbariyat from "./components/pages/Rahbariyat/Rahbariyat";
import Legacy from "./components/pages/Legacy/Legacy";
import Compound from "./components/pages/Compound/Rahbariyat";
import NewsPage from "./components/pages/News/News";
import YouthPolitics from "./components/pages/Home/YouthPolitics";
import ActivityShow from "./components/pages/Home/ActivityShow";
import International from "./components/pages/International/International";
import Maqola from "./components/pages/Home/Maqola";
import AnswerToQuestion from "./components/pages/Home/AnswerToQuestion";
import Finance from "./components/pages/Home/Finance";
import Vacancy from "./components/pages/Home/Vacancy";
import Responsible from "./components/pages/Responsible/Responsible";
import GetInformation from "./components/pages/Home/GetInformation";
import InformationService from "./components/pages/Home/InformationService/InformationService";
import Goverment from "./components/pages/Home/Goverment";
import CenterData from "./components/pages/CenterData";
import Citiziens from "./components/pages/Citiziens";
import Buxoro from "./components/pages/YoymaRoyxat/Buxoro";
import Elonlar from "./components/pages/Home/Elonlar";
import ElonlarIn from "./components/pages/Home/ElonlarIn";
import Work from "./components/pages/Work";
import Tuzilma from "./components/pages/Home/Tuzilma";
import MarkaziyApparat from "./components/pages/MarkaziyApparat/MarkaziyApparat";
import Xizmat from "./components/pages/Home/Xizmat";
import Siyosat from "./components/pages/Home/Siyosat";
import RR from "./components/pages/Home/RR";
import Korruption from "./components/pages/Documents/Korruption";
// import Rahbar from "./components/pages/Rahbariyat/Rahbar";
import Rahbar1 from "./components/pages/Rahbariyat/Rahbar";
import Rahbar2 from "./components/pages/Rahbariyat/Rahbar2";
import Rahbar3 from "./components/pages/Rahbariyat/Rahbar3";
import Vazifa1 from "./components/pages/Rahbariyat/Vazifa1";
import Vazifa2 from "./components/pages/Rahbariyat/Vazifa2";
import Vazifa3 from "./components/pages/Rahbariyat/Vazifa3";
import Axborot1 from "./components/pages/Home/Axborot1";
import Axborot2 from "./components/pages/Home/Axborot2";
import Axborot3 from "./components/pages/Home/Axborot3";
import Axborot4 from "./components/pages/Home/Axborot4";
import Jamoatchilik from "./components/pages/Home/Jamoatchilik";
import FotoIn from "./components/pages/Home/FotoIn";
import Foot from "./components/Foot";
import MarkazAnjumani from "./components/pages/Home/MarkazAnjumani";
import InFotogalareya from "./components/pages/Home/InFotogalareya";
import Axborot5 from "./components/pages/Home/Axborot5";
// import InVideogalareya from "./components/pages/Home/InFotogalareya";
import Videogalareya from "./components/pages/Home/Videogalareya";

i18n.use(initReactI18next).init({
  resources: {
    ru: { translation: translationsRu },
    uz: { translation: translationsUz },
    oz: { translation: translationsUzKril },
    en: { translation: translationsEn },
  },
  lng: "uz",
  fallbackLng: "uz",
});

const App = () => {
  const { pathname } = useLocation();
  const changeLang = (value) => {
    i18next.changeLanguage(value);
  };
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="app">
      <img className="naqsh" src={Leftbg} alt="" />
      <img className="naqsh2" src={Rightbg} alt="" />

      <a className="eco-btn" href="http://eco-service.uz/">
        <div className="ekoservice-btn">
          <img src={Ekoservice} alt="" />
          <p>eco-service</p>
        </div>
      </a>

      {/* <div className="ekoservice-btn2 eco-btn" >
        <img src={Kalkulator} alt="" />
        <p>Калькулятор</p>
      </div> */}

      <Button
        className="ekoservice-btn2 eco-btn"
        onClick={() => setModalShow(true)}
      >
        <img src={Kalkulator} alt="" />
        <p>Калькулятор</p>
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Navbar changeLang={changeLang} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-commitee" element={<AboutCommitee />} />
        <Route path="/legacy" element={<Legacy />} />
        <Route path="/compound" element={<Compound />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/news" element={<News />} />
        <Route path="/moretempus" element={<Moretempus />} />
        <Route path="/readmorenews/:id" element={<ReadMoreNews />} />
        <Route path="/elon/:id" element={<ElonlarIn />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/information/:id" element={<Andijon />} />
        <Route path="/information/:id" element={<Buxoro />} />
        <Route path="/markaz-tarixi" element={<Markaztarixi />} />
        <Route path="/jamoatchilikeshituvi" element={<YouthPolitics />} />
        <Route path="/faoliyat-ko'rsatkichlari" element={<ActivityShow />} />
        <Route path="/xalqaro-aloqalar" element={<International />} />
        <Route path="/korrupsiya" element={<Korrupsion />} />
        <Route path="/rahbariyat" element={<Rahbariyat />} />
        <Route path="/yoshlarsiyosati" element={<Maqola />} />
        <Route path="/savol-javoblar" element={<AnswerToQuestion />} />
        <Route path="/opendata-finance" element={<Finance />} />
        <Route path="/opendata-vacancy" element={<Vacancy />} />
        <Route path="/opendata-responsible" element={<Responsible />} />
        <Route path="/opendata-get-information" element={<GetInformation />} />
        <Route path="/opendata-info-center" element={<InformationService />} />
        <Route path="/opendata-goverment" element={<Goverment />} />
        <Route path="/opendata-center-data" element={<CenterData />} />
        <Route path="/opendata-citiziens-data" element={<Citiziens />} />
        <Route path="/elonlar" element={<Elonlar />} />
        <Route path="/work" element={<Work />} />
        <Route path="/tuzilma" element={<Tuzilma />} />
        <Route path="/markaziyapparat" element={<MarkaziyApparat />} />
        <Route path="/axborot-xizmati" element={<Xizmat />} />
        <Route path="/siyosat/:id" element={<Siyosat />} />
        <Route path="/rr/:id" element={<RR />} />
        <Route path="/corruption" element={<Korruption />} />
        <Route path="/biografiya" element={<Rahbar1 />} />
        <Route path="/biografiya2" element={<Rahbar2 />} />
        <Route path="/biografiya3" element={<Rahbar3 />} />
        <Route path="/vazifa1" element={<Vazifa1 />} />
        <Route path="/vazifa2" element={<Vazifa2 />} />
        <Route path="/vazifa3" element={<Vazifa3 />} />
        <Route path="/axborot1" element={<Axborot1 />} />
        <Route path="/axborot2" element={<Axborot2 />} />
        <Route path="/axborot3" element={<Axborot3 />} />
        <Route path="/axborot4" element={<Axborot4 />} />
        <Route path="/axborot5" element={<Axborot5 />} />
        <Route path="/jamoatchilik/:id" element={<Jamoatchilik />} />
        <Route path="/markaz-anjumani/:id" element={<MarkazAnjumani />} />
        <Route path="in-fotogalareya/:id" element={<InFotogalareya />} />
        <Route path="in-videogalareya/:id" element={<Videogalareya />} />
      </Routes>

      <Foot/>
    </div>
  );
};

export default App;
