import React, { useContext, useEffect, useState} from 'react'
import { GlobalContext } from '../../GlobalContext'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { GET_YOUTH } from '../../../constants/urls'
import { useTranslation } from 'react-i18next'

export default function Maqola() {
  const { t } = useTranslation();
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async () => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_YOUTH)}`)
    if (info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])

  return (
    <section className='Markaztarixi'>
    {data?.length ? data?.map((item, index) => (
      <div className="containerr" key={index}>

        <div className="mark_box">
          <div className="mark_l">
            <img className='markaztarixi-img' src={item?.image} alt="" />
          </div>
          <div className="mark_r">
            <div className='mark_h'>{item?.title}</div>
            <div className='markaz-tarixi__info'>
              <div className='mark_p' dangerouslySetInnerHTML={{ __html: item?.content.slice(0,1250)+"..." }} />
            </div>
          </div>
        </div>



      </div>
    )) : ''}
  </section>
  )
}
