import React from 'react'

export default function Loader() {
  return (
    <section className='Loader'>
      <div className="containerr">
      <div class="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
      </div>
    </section>
  )
}
