import React, { useEffect, useState } from 'react'
import Pdf from '../../../assets/images/icons/pdf-img.svg'
import Doc from '../../../assets/images/icons/doc-img.svg'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_PATH } from '../../../constants/tools';

export default function Decrees() {

  const [doc, setDoc] = useState([])

  useEffect(() => {
    axios
      .get(API_PATH + '/uz/news/documents/')
      .then(res => {
        setDoc(res.data)
      })
  }, [])


  return (
    <section className='Decrees' data-aos="fade-right">
      <div className='containerr'>


        {doc && doc.map((item, index) => {
          return (
            <div key={index} className='decrees-info'>
              <div className='decrees-data'>
                <h3>{item.day}</h3>
                <h4>{item.month}</h4>
                <p>{item.year}</p>
              </div>

              <div className="decrees-title">
                <p>{item.title}</p>
                <div >
                <a target={'_blank'} rel="noreferrer" href={item.file}><img src={Pdf} alt="" /></a>
                  {/* <a target={'_blank'} rel="noreferrer" href={item.link}><img src={Doc} alt="" /></a> */}
                </div>
              </div>
            </div>

          )
        })}




      </div>
    </section>
  )
}
