import React, { useEffect, useState, useContext } from "react";
import "./rahbariyat.css";
import axios from "axios";
import { API_PATH } from "../../../constants/tools";
import { useTranslation } from "react-i18next";
import { GET_LIST_DIRECTORS } from "../../../constants/urls";
import { GlobalContext } from "../../GlobalContext";
import { Link } from "react-router-dom";

export default function Rahbariyat({ changeLang }) {
  const { t } = useTranslation();
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async () => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_LIST_DIRECTORS)}`)
    if (info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])


  return (
    <>
      <div className="rahbariyat">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row">
              {/* <h1>Ёйма рўйхат экспертиза Марказий аппарат</h1> */}
              <hr />
              <div className="col-lg-12 mx-auto">
                <div className="row">

                  <p className="p_two">
                    {t("rahbar1")}
                  </p>
                </div>
                <div className="col-lg-12 mx-auto">
                  <div className="row">
                    <div className="for_rahbariyat">
                      {/* <p className="p_three">РАҲБАРИЯТ</p> */}
                      {data &&
                        data.slice(0, 1).map((item, index) => {
                          return (
                            <div key={index} className="col-lg-12 mx-auto">
                              <div className="rahbariya_1">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img className="rah_img" src={item?.image} alt="" />
                                  </div>

                                  <div className="col-lg-8">
                                    <p className="p_four">{t("rahbar2")} </p>
                                    <p className="p_five">
                                      {item?.name}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar3")}
                                    </p>
                                    <p className="p_five">
                                      {item?.address}
                                    </p>
                                    <p className="p_four">{t("rahbar4")}</p>
                                    <p className="p_five">{item?.phone}</p>
                                    <p className="p_four">
                                      {t("rahbar5")}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.title}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar6")}  {" "}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.email}
                                    </p>
                                    <div className="for_new_rahbariyat" >
                                      <Link to="/biografiya" style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr7")}
                                      </Link>
                                      <Link to="/vazifa1" style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr8")}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-5">

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}



                      {data &&
                        data.slice(1, 2).map((item, index) => {
                          return (
                            <div key={index} className="col-lg-12 mx-auto">
                              <div className="rahbariya_1">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img className="rah_img" src={item?.image} alt="" />
                                  </div>

                                  <div className="col-lg-8">
                                    <p className="p_four">{t("rahbar2")} </p>
                                    <p className="p_five">
                                      {item?.name}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar3")}
                                    </p>
                                    <p className="p_five">
                                      {item?.address}
                                    </p>
                                    <p className="p_four">{t("rahbar4")}</p>
                                    <p className="p_five">{item?.phone}</p>
                                    <p className="p_four">
                                      {t("rahbar5")}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.title}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar6")}  {" "}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.email}
                                    </p>
                                    <div className="for_new_rahbariyat" >
                                      <Link to="/biografiya2" style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr7")}
                                      </Link>
                                      <Link to="/vazifa2" style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr8")}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-5">

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}


                      {data &&
                        data.slice(2, 3).map((item, index) => {
                          return (
                            <div key={index} className="col-lg-12 mx-auto">
                              <div className="rahbariya_1">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img className="rah_img" src={item?.image} alt="" />
                                  </div>

                                  <div className="col-lg-8">
                                    <p className="p_four">{t("rahbar2")} </p>
                                    <p className="p_five">
                                      {item?.name}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar3")}
                                    </p>
                                    <p className="p_five">
                                      {item?.address}
                                    </p>
                                    <p className="p_four">{t("rahbar4")}</p>
                                    <p className="p_five">{item?.phone}</p>
                                    <p className="p_four">
                                      {t("rahbar5")}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.title}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar6")}  {" "}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.email}
                                    </p>
                                    <div className="for_new_rahbariyat" >
                                      <Link to="/biografiya3" style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr7")}
                                      </Link>
                                      <Link to="/vazifa3" style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr8")}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="col-5">

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}


                      {data &&
                        data.slice(3, 4).map((item, index) => {
                          return (
                            <div key={index} className="col-lg-12 mx-auto">
                              <div className="rahbariya_1">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img className="rah_img" src={item?.image} alt="" />
                                  </div>

                                  <div className="col-lg-8">
                                    <p className="p_four">{t("rahbar2")} </p>
                                    <p className="p_five">
                                      {item?.name}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar3")}
                                    </p>
                                    <p className="p_five">
                                      {item?.address}
                                    </p>
                                    <p className="p_four">{t("rahbar4")}</p>
                                    <p className="p_five">{item?.phone}</p>
                                    <p className="p_four">
                                      {t("rahbar5")}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.title}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar6")}  {" "}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.email}
                                    </p>
                                    <div className="for_new_rahbariyat" >
                                      <a style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr7")}
                                      </a>
                                      <a style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr8")}
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-5">

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {data &&
                        data.slice(4, 5).map((item, index) => {
                          return (
                            <div key={index} className="col-lg-12 mx-auto">
                              <div className="rahbariya_1">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img className="rah_img" src={item?.image} alt="" />
                                  </div>

                                  <div className="col-lg-8">
                                    <p className="p_four">{t("rahbar2")} </p>
                                    <p className="p_five">
                                      {item?.name}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar3")}
                                    </p>
                                    <p className="p_five">
                                      {item?.address}
                                    </p>
                                    <p className="p_four">{t("rahbar4")}</p>
                                    <p className="p_five">{item?.phone}</p>
                                    <p className="p_four">
                                      {t("rahbar5")}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.title}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar6")}  {" "}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.email}
                                    </p>
                                    <div className="for_new_rahbariyat" >
                                      <a style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr7")}
                                      </a>
                                      <a style={{ textDecoration: "underline" }} className="p_sixs a_text">
                                        {t("kr8")}
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-5">

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
