import React from 'react'

const FotoIn = () => {
    return (
        <>

            <div className="Axborot1">
                <div className="containerr">
                    <div className="row">
                        <div className="col-12">
                            <div className="axb_nam">
                                Fotogalereya
                            </div>
                        </div>

                        <div className="col-4 mt-5 ">
                            <img className='w-100 h-100' src="/img/ph_7.png" alt="" />
                        </div>
                        <div className="col-4 mt-5 ">
                            <img className='w-100 h-100' src="/img/ph_7.png" alt="" />
                        </div>
                        <div className="col-4 mt-5 ">
                            <img className='w-100 h-100' src="/img/ph_7.png" alt="" />
                        </div>
                        <div className="col-4 mt-5 ">
                            <img className='w-100 h-100' src="/img/ph_7.png" alt="" />
                        </div>

                        <div className="col-12">
                            <div className="foto_in_h">
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quaerat, rerum sint est rem eveniet cumque delectus? Voluptas laboriosam accusantium, libero possimus autem impedit tempora tempore quisquam officia vel, eum facere?
                            </div>
                            <div className="foto_in_box">
                                <div className="foto_in_main">
                                    <img src="/img/icon_eye_1_1.png" alt="" />
                                    222
                                </div>
                                <div className="foto_in_main">
                                    <img src="/img/icon_eye_1_2.png" alt="" />
                                    222
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FotoIn