import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { API_PATH } from '../../../constants/tools'
import {  GET_INFORMATION } from '../../../constants/urls'
import { GlobalContext } from '../../GlobalContext'
export default function GetInformation() {
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async() => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_INFORMATION)}`)
    if(info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])

  return (
    <section className='Markaztarixi'>
      {data?.length ? data?.map((item, index) => (
        <div className="containerr" key={index}>
          <h2>{item?.title}</h2>
          <div className='markaz-tarixi__info'>
           <p dangerouslySetInnerHTML={{ __html: item?.content }}/>
          </div>
        </div>
      )) : ''}
    </section>
  )
}
