import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { API_PATH } from '../../../constants/tools'
import { GET_ACTIVITY_SHOW } from '../../../constants/urls'
import { GlobalContext } from '../../GlobalContext'

export default function ActivityShow() {
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async() => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_ACTIVITY_SHOW)}`)
    if(info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])

  return (
    <section className='Markaztarixi'>
      {data?.length ? data.map((item, index) => (
        <div className="containerr" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} key={index}>
        <img style={{ width: '60%' }} src={item.image} alt="" />
        <h2>{item.title}</h2>
        <div className='markaz-tarixi__info'>
          <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item?.content }}/>
        </div>
      </div>
      )) : ''}
    </section>
  )
}
