import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../GlobalContext'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { GET_YOUTH } from '../../../constants/urls'
import { Link, useLocation } from 'react-router-dom'
const Siyosat = () => {

    const [id, setId] = useState({});
    const { lang } = useContext(GlobalContext)
    const location = useLocation();
    const path = location.pathname.split("/")[2];


    const getId = async () => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_YOUTH)}${path}/`)
            .then((res) => {
                setId(res.data);
            })
        if (info?.data?.length) {
            setId(info.data)
        }
    }


    useEffect(() => {
        getId()
    }, [lang, path])


    return (
        <>

            <div className="Siyosat">
                <div className="containerr">
                    {id.data &&
                        id.data.map((item, index) => (
                            <div key={index} className="row">
                                <div className="col-12">
                                    <img className='w-100' src={item?.img} alt="" />
                                </div>
                                <div className="col-12">
                                    <div className="siyosat_h">{item?.title}</div>
                                    <div className="siyosat_p" dangerouslySetInnerHTML={{ __html: item?.content }} />
                                </div>
                            </div>
                        ))}


                </div>
            </div>

        </>
    )
}

export default Siyosat