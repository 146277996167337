import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function VacancyComponent({item ,index}){
    const [toggle, setToggle] = useState(false)
    const { t } = useTranslation()
    return (
        <>
                <div key={index} style={{ width: '75%' }} className="question-card py-5 d-flex flex-row justify-content-between align-items-center">
                    <div className="pe-4">{index + 1}. {item?.name}</div>
                    <div className="question-card-pointer" onClick={() => setToggle(!toggle)}>
                        {toggle ? '-' : '+'}
                    </div>
                </div>

                { toggle ? (
                    <div key={index} style={{ width: '75%' }} className="question-card py-5 d-flex flex-column justify-content-between">
                        <div>{t('vacancy_company')}:   {item?.company}</div>
                        <div>{t('vacancy_location')}:   {item?.location}</div>
                        <div>{item?.text}</div>
                    </div>
                ): ''}
        </>
    )
}