import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import animation2 from '../assets/images/icons/animation2.mp4'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Ecobtn from "../assets/images/icons/menu-eco-btn.png"
import Calculaotr from '../assets/images/icons/menu-calculator.png'
import Telegram from '../assets/images/icons/telegram.png'
import Facebook from '../assets/images/icons/facebook.png'
import { GlobalContext } from "./GlobalContext";
import img from '../assets/images/icons/ishonchtel.png'
// import Logo from '/../public/Logo.png'
import Logo from '../assets/images/icons/Logo.png'


const Navbar = ({ changeLang }) => {
  const { setLang } = useContext(GlobalContext)
  const [active, setActive] = useState('menus');
  const [toggleIcon, setToggleIcon] = useState('nav__toggler')
  const [search, setSearch] = useState('search')


  const { t } = useTranslation();
  const changeLanguage = (e) => {
    changeLang(e.target.value)
    if (e.target.value === 'oz') {
      setLang('ky')
    } else {
      setLang(e.target.value)
    }
    localStorage.setItem("LANGUAGE", e.target.value)
  }

  const searchInput = () => {
    search === 'search'
      ? setSearch('search search-active')
      : setSearch('search')
  }

  const navToggle = () => {
    active === 'menus'
      ? setActive('menus nav__active')
      : setActive('menus')

    toggleIcon === 'nav__toggler'
      ? setToggleIcon('nav__toggler toggle')
      : setToggleIcon('nav__toggler')
  };

  function refreshPage() {
    window.location.reload(true);
  }



  return (
    <div className="nav">
      <div className="containerr">
        <div className="nav1">
         <div className="nav_flex">
         <Link to='/' className="logo ">
            <div className="nav_box">
              <div>
                <div className="d-flex align-items-center">
                  <img className="logo_new" style={{ width: "50px" }} src={Logo} alt="" />
                  <p className='logo-info' >{t('herotittle')}</p>
                </div>
                {/* <div className="nav_test">
                  {t('nav_test')}
                </div> */}
              </div>

            </div>
          </Link>

          <div className='line-info d-lg-flex d-none'>       
                <div className="foot_main_box2">
                <img src="/img/icon_foot_main_3.png" alt="" />
                  <div><p>Call Centrer</p>
                  <li>
                    <a href="tel:+998712030304" >+998 71 203 03 04</a>
                  </li></div>
                </div>
              </div>
         </div>

          {/* <div className="nav_info">
            <div className='line-info d-lg-flex d-none'>
            <div className="foot_main_box2">
            <img src="/img/arrow.png" alt="" />
              <div>
                <p>{t('nav_info_1')}</p>
                <li>
                  <a href="mailto:info@ecoekspertiza.uz" >info@ecoekspertiza.uz</a>
                </li>
              </div>
            </div>
            </div>
            
          </div> */}



          <div className="login_btn">
            <ul className="nav1_socials">
              <li>
                <a className="nav_ic_a" href="https://t.me/ecoekspertiza"  target="_blank">
                  <img src="/img/telegram.png " alt="" />
                </a>
              </li>

              <li>
                <a className="nav_ic_a" href="https://www.facebook.com/ecoekspertiza/"  target="_blank">
                  <img src='/img/facebook.png' alt="" />
                </a>
              </li>

              <li>
                <a className="nav_ic_a" href="https://www.youtube.com/channel/UCk1-8z1uI0fWDQRniifg6xw"  target="_blank">
                  <img src='/img/youtube.png' alt="" />
                </a>
              </li>
            </ul>

            <select className="language" name="languages" id="languages" onChange={changeLanguage}>
              <option onClick={refreshPage} value="uz">UZ</option>
              <option onClick={refreshPage} value="oz">ЎЗ</option>
              <option onClick={refreshPage} value="ru">РУ</option>
              <option onClick={refreshPage} value="en">EN</option>
            </select>

          </div>
        </div>
        <ul className={active}>
          <NavDropdown title={t('about')} id="basic-nav-dropdown" style={{ zIndex: 100 }}>
            {/* 
            <Link className="dropdown-item" onClick={navToggle} to='/legacy'>{t('nav_2')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to='/markaz-tarixi'>{t('nav_4')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to="/elonlar">{t('nav_6')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to="/yoshlar-siyosati">{t('nav_7')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to="/faoliyat-ko'rsatkichlari">{t('nav_8')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to="/xalqaro-aloqalar">{t('nav_9')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to="/korrupsiya">{t('nav_10')}</Link> */}

            <Link className="dropdown-item" onClick={navToggle} to='/about-commitee'>{t('nav_1')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to='/tuzilma'>{t('nav_1_2')}</Link>
            <Link className="dropdown-item" to="/activity" onClick={navToggle}>{t('nav_11')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to="/rahbariyat">{t('nav_5')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to='/markaziyapparat'>{t('nav_3')}</Link>
            <Link className="dropdown-item" onClick={navToggle} to='/compound'>{t('nav_3_2')} </Link>
            <Link to="/documents" onClick={navToggle} className="dropdown-item">
              {t('documents')}
            </Link>
            <Link className="dropdown-item" to='/xalqaro-aloqalar' onClick={navToggle}>{t('nav_19_1')}</Link>
            <Link className="dropdown-item" to='/opendata-vacancy' onClick={navToggle}>{t('nav_19')}</Link>
          </NavDropdown>
          <li className="nav__item">
            <Link to="/news" onClick={navToggle} className="links nav-link">
              {t('news')}
            </Link>
          </li>


          {/* <NavDropdown className="links" title={t('nav_4_2')} id="basic-nav-dropdown" style={{ zIndex: 100 }}>
            <Link className="dropdown-item" to="/maqola" onClick={navToggle}>{t('nav_12')}</Link>
            <NavDropdown.Item target={'_blank'} rel="noreferrer" href="https://eco-service.uz/">{t('nav_14')}</NavDropdown.Item>
            <Link className="dropdown-item" to="/savol-javoblar" onClick={navToggle}>{t('nav_16')}</Link>
          </NavDropdown> */}

          <NavDropdown className="links" title={t('nav_5_2')} id="basic-nav-dropdown" style={{ zIndex: 100 }}>
            <a href="https://eco-service.uz/public/pages/main_conclusions" target="_blank" className="dropdown-item">{t('rahbar_2')}</a>
            <a href="https://eco-service.uz/public/pages/main_rates" target="_blank" className="dropdown-item">{t('rahbar_3')}</a>
            <Link className="dropdown-item" to='/opendata-finance' onClick={navToggle}>{t('nav_18')}</Link>
            {/* <Link className="dropdown-item" to='/opendata-responsible' onClick={navToggle}>{t('nav_20')}</Link> */}
            <Link className="dropdown-item" to='/opendata-get-information' onClick={navToggle}>{t('nav_21')}</Link>
            {/* <Link className="dropdown-item" to='/opendata-info-center' onClick={navToggle}>{t('nav_22')}</Link> */}
            <Link className="dropdown-item" to="/yoshlarsiyosati" onClick={navToggle}>{t('nav_12')}</Link>
            {/* <a target="_blank" className="dropdown-item" href="https://eco-service.uz/sys/notification/list_search" style={{ marginLeft: 10 }}>
              {t('nav_23')}
            </a> */}
            <Link className="dropdown-item" to='/opendata-goverment' onClick={navToggle}>{t('nav_24')}</Link>
            <Link className="dropdown-item" to='/opendata-center-data' onClick={navToggle}>{t('nav_25')}</Link>
            {/* <NavDropdown.Item ><Link className="dropdown-item" to='/opendata-citiziens-data' onClick={navToggle}>{t('nav_26')}</Link></NavDropdown.Item> */}

          </NavDropdown>

          <NavDropdown className="links" title={t('rahbar_4')} id="basic-nav-dropdown" style={{ zIndex: 100 }}>
            <Link className="dropdown-item" to='/jamoatchilikeshituvi' onClick={navToggle}>{t('rahbar_5')}</Link>
            <Link className="dropdown-item" to='/axborot1' onClick={navToggle}>{t('rahbar_10')}</Link>
            <Link className="dropdown-item" to='/axborot2' onClick={navToggle}>{t('rahbar_11')}</Link>
            <Link className="dropdown-item" to='/axborot3' onClick={navToggle}>{t('rahbar_12')}</Link>
            <Link className="dropdown-item" to='/axborot4' onClick={navToggle}>{t('rahbar_13')}</Link>
            <Link className="dropdown-item" to='/axborot5' onClick={navToggle}>{t('rahbar_14')}</Link>


          </NavDropdown>

          {/* 
          <li className="nav__item">
            <Link to="/axborot-xizmati" onClick={navToggle} className="links nav-link">
              {t('rahbar_4')}
            </Link>
          </li> */}


          {/* <li className="nav__item">
            <Link to="/xalqaro-aloqalar" onClick={navToggle} className="links nav-link">
              {t('rahbar7')}
            </Link>
          </li> */}



          <li className="nav__item">
            <Link to="/corruption" onClick={navToggle} className="links nav-link">
              {t('nav_10')}
            </Link>
          </li>


          {/* <li className="nav__item">
          
            <a href="https://eco-service.uz/public/pages/main_conclusions" target="_blank" className="links nav-link">{t('rahbar_2')}</a>
          </li>
          <li className="nav__item">
          
            <a href="https://eco-service.uz/public/pages/main_rates" target="_blank" className="links nav-link">{t('rahbar_3')}</a>
          </li> */}

          {/* <li className="nav__item">
            <Link to="/documents" onClick={navToggle} className="links nav-link">
              {`Markaz xizmatlari`}
            </Link>
          </li> */}
          <li className="nav__item">
            <Link to="/contact" onClick={navToggle} className="links nav-link">
              {t('contact')}
            </Link>
          </li>


          <a className="menu-btn" href="http://eco-service.uz/">
            <div className="menu-eco">
              <img src={Ecobtn} alt="" />
              <p>eco-service</p>
            </div>
          </a>

          <div className="menu-btn">
            <div className="menu-eco">
              <img src={Calculaotr} alt="" />
              <p> {t('calculator')}</p>
            </div>
          </div>

          <div className="nav2_socials">
            <ul>
              <li>
                <a href="https://t.me/ecoekspertiza">
                  <svg className="nav2-icons" width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 0.5C6.937 0.5 0 7.438 0 16C0 24.562 6.938 31.5 15.5 31.5C24.063 31.5 31 24.562 31 16C31 7.438 24.062 0.5 15.5 0.5ZM23.113 11.119L20.569 23.107C20.381 23.957 19.875 24.163 19.169 23.763L15.294 20.907L13.425 22.707C13.219 22.913 13.044 23.088 12.644 23.088L12.919 19.144L20.1 12.656C20.413 12.381 20.031 12.225 19.618 12.5L10.743 18.087L6.918 16.893C6.087 16.631 6.068 16.062 7.093 15.662L22.037 9.899C22.731 9.649 23.337 10.068 23.112 11.118L23.113 11.119Z" fill="#336C5F" />
                  </svg>
                </a>
              </li>

              <li>
                <a href="https://www.facebook.com/ecoekspertiza/">
                  <svg className="nav2-icons" width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.4997 0C6.93954 0 0 6.93969 0 15.4997C0 24.0595 6.93954 31 15.4997 31C24.0599 31 31 24.0598 31 15.4997C30.9997 6.93969 24.0599 0 15.4997 0ZM20.7932 9.9148C20.7932 10.0627 20.6734 10.1828 20.5252 10.1828H16.7519V12.2108H20.5249C20.6731 12.2108 20.7929 12.3309 20.7929 12.4788V16.2515C20.7929 16.3997 20.6731 16.5195 20.5249 16.5195H17.0199V24.7987C17.0199 24.9469 16.9001 25.0667 16.7519 25.0667H13.186C13.0378 25.0667 12.918 24.9469 12.918 24.7987V16.5198H10.4746C10.3264 16.5198 10.2066 16.4 10.2066 16.2517V12.4791C10.2066 12.3311 10.3264 12.2111 10.4746 12.2111H12.918V10.6813C12.918 10.6593 12.9207 10.6381 12.9255 10.6178C12.9277 8.43864 13.3937 5.94566 16.9639 5.93441C16.9716 5.93387 16.9799 5.93334 16.988 5.93334H20.5249C20.6731 5.93334 20.7929 6.0534 20.7929 6.20134V9.9148H20.7932Z" fill="#336C5F" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

        </ul>

        <div className="menu3">
          <div className="nav3">
            <div className="login_btn2">
              <select className="language" name="languages" id="languages" onChange={changeLanguage}>
                <option value="uz">UZ</option>
                <option value="oz">ЎЗ</option>
                <option value="ru">РУ</option>
                <option value="en">EN</option>
              </select>
            </div>
          </div>
        </div>


        <div onClick={navToggle} className={toggleIcon}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
    </div>
  );
};



export default Navbar;
