import React, { useContext, useState, useEffect} from "react";
import { API_PATH } from "../../../constants/tools";
import axios from "axios";
import { GlobalContext } from "../../GlobalContext";
import './home.css'
import { GET_ANSWERS_TO_QUESTIONS } from "../../../constants/urls";
import AnswerComponent from "./AnswerComponent";

export default function AnswerToQuestion(){
    const [data, setData] = useState([])
    const { lang } = useContext(GlobalContext)
    const getData = async() => {
      const info = await axios(`${API_PATH.concat(lang).concat(GET_ANSWERS_TO_QUESTIONS)}`)
      if(info?.data?.length) {
        setData(info.data)
      }
    }
  
    useEffect(() => {
      getData()
    }, [lang])

    return (
        <div className="d-flex flex-column align-items-center container-fluid mb-3">
           {data?.length ? data?.map((item, index) => (
              <AnswerComponent index={index} item={item}/>
           )) : ''}
        </div>
    )
}