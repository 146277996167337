import React from "react";
import Moreimg from "../../../assets/images/icons/more-news.png";
import Moreimg1 from "../../../assets/images/icons/MoreInfo1.jpg";
import Moreimg2 from "../../../assets/images/icons/MoreInfo2.jpg";
import Moreimg3 from "../../../assets/images/icons/MoreInfo3.jpg";
import Moreimg4 from "../../../assets/images/icons/MoreInfo4.jpg";
import Moreimg5 from "../../../assets/images/icons/Moreimg5.jpg";
import Moreimg6 from "../../../assets/images/icons/Newsinfo6.jpg";
import Moreimg7 from "../../../assets/images/icons/NewsInfo7.jpg";
import Moreimg8 from "../../../assets/images/icons/NewsInfo8.jpg";
import Moreimg9 from "../../../assets/images/icons/NewsInfo9.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API_PATH } from "../../../constants/tools";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

export default function MoreNews({ changeLang }) {
  const [news, setNews] = useState([]);
  const T = useTranslation();
  const getNews = async () => {
    await axios
      .get(API_PATH + `/${T.i18n.language}/news/list-news/`)
      .then((res) => {
        setNews(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNews();
  }, [T.i18n.language]);

  const { t } = useTranslation();
  const changeLanguage = (e) => {
    changeLang(e.target.value);
  };
  return (
    <section className="MoreNews">
      <div className="containerr">
        <h2>{t("news")}</h2>

        <div className="more-news-cards">
          {news &&
            news.map((item, index) => (
              <div key={index} className="more-news-card">
                <img className="more-news-card-img" src={item.image} alt="" />
                <p className="more-news-card-data">
                  {item.created_at.slice(0, 10)}
                </p>
                <p className="more-news-card-title">{item.title}</p>
                <p className="more-news-card-text">{item.content}</p>
                <Link
                  to={`/readmorenews/${item.id}`}
                  className="read-more-link"
                >
                  {t("more")}
                </Link>
                <div className="foto_info">
                  <div className="foto_main">
                    <img src="/img/icon_eye_1_1.png" alt="" />
                    {item.views}
                  </div>
                  <div className="foto_main">
                    <img src="/img/icon_eye_1_2.png" alt="" />
                    {item.created_at}
                  </div>
                </div>
              </div>
            ))}

        </div>

      </div>
    </section>
  );
}
