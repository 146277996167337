import React, { useState } from "react";

export default function AnswerComponent({item, index}){
    const [toggle, setToggle] = useState(false)
    return (
        <>
            <div key={index} style={{ width: '75%' }} className="question-card py-5 d-flex flex-row justify-content-between align-items-center">
                <div className="pe-4">{index + 1}. {item?.question}</div>
                <div className="question-card-pointer" onClick={() => setToggle(!toggle)}>
                    {toggle ? '-' : '+'}
                </div>
            </div>

            { toggle ? (
                <div key={index} style={{ width: '75%' }} className="question-card py-5 d-flex flex-row justify-content-between align-items-center">
                    {item?.answer}
                </div>
            ): ''}
       </>
    )
}