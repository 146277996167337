import React, { useContext, useEffect, useState} from 'react'
import { GlobalContext } from '../../GlobalContext'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { GET_INTERNATIONAL } from '../../../constants/urls'
import { useTranslation } from 'react-i18next'

export default function International({ changeLang }) {
  const {t} = useTranslation()
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async() => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_INTERNATIONAL)}`)
    if(info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])

  console.log(data)



  return (
    <>
      <div className="rahbariyat">
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row">
              {/* <h1>Ёйма рўйхат экспертиза Марказий аппарат</h1> */}
              <hr />
              <div className="col-lg-12 mx-auto">
                <div className="row">
                  {/* <p className="p_one">
                    Ўзбекистон Республикаси Экология ва атроф-муҳитни муҳофаза
                    қилиш давлат қўмитасининг
                  </p> */}
                  <p className="p_two">
                    {t("nav_19_1")}
                  </p>
                </div>
                <div className="col-lg-12 mx-auto">
                  <div className="row">
                    <div className="for_rahbariyat">
                      {/* <p className="p_three">РАҲБАРИЯТ</p> */}
                      {data &&
                        data.map((item, index) => {
                          return (
                            <div key={index} className="col-lg-12 mx-auto">
                              <div className="rahbariya_1">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <img src={item?.image} alt="" />
                                  </div>

                                  <div className="col-lg-9">
                                    {/* <p className="p_four">{t("rahbar2")} </p> */}
                                    <p className="p_five">
                                      {item?.name}
                                    </p>
                                    <p className="p_five" dangerouslySetInnerHTML={{ __html: item?.text }}/>
                                  </div>
                                  <div className="col-5">

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
