import React from 'react'
import Decrees from './Decrees'
import { useTranslation } from 'react-i18next';
import Pdf from '../../../assets/images/icons/pdf-img.svg'
import pdf1 from '../../../assets/files/Call center.pdf'
import pdf2 from '../../../assets/files/jamoatchilik shartnoma 11 son.pdf'
import pdf3 from '../../../assets/files/jamoatchilik shartnoma5.pdf'

export default function Korruption({ changeLang }) {
  const { t } = useTranslation();
  const changeLanguage = (e) => {
    changeLang(e.target.value)
  }
  return (
    <section className='Documents'>




      <section className='Decrees' data-aos="fade-right">
        <div className='containerr'>
          <div className="korruption">
            <h2>{t("kr1")}</h2>
          </div>

          <div className='decrees-info'>
            <div className='decrees-data'>
              <h3>3</h3>
              <h4>{t("kr2")}</h4>
              <p>2024</p>
            </div>

            <div className="decrees-title">
              <p>{t("kr3")}</p>
              <div >
                <a target={'_blank'} rel="noreferrer" href={pdf1}><img src={Pdf} alt="" /></a>
                {/* <a target={'_blank'} rel="noreferrer" href={item.link}><img src={Doc} alt="" /></a> */}
              </div>
            </div>
          </div>

          <div className='decrees-info'>
            <div className='decrees-data'>
              <h3>3</h3>
              <h4>{t("kr2")}</h4>
              <p>2024</p>
            </div>

            <div className="decrees-title">
              <p>{t("kr4")}</p>
              <div >
                <a target={'_blank'} rel="noreferrer" href={pdf2}><img src={Pdf} alt="" /></a>
                {/* <a target={'_blank'} rel="noreferrer" href={item.link}><img src={Doc} alt="" /></a> */}
              </div>
            </div>
          </div>

          <div className='decrees-info'>
            <div className='decrees-data'>
              <h3>8</h3>
              <h4>{t("kr5")}</h4>
              <p>2022</p>
            </div>

            <div className="decrees-title">
              <p>{t("kr6")}
              </p>
              <div >
                <a target={'_blank'} rel="noreferrer" href={pdf3}><img src={Pdf} alt="" /></a>
                {/* <a target={'_blank'} rel="noreferrer" href={item.link}><img src={Doc} alt="" /></a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

    </section>
  )
}
