import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { API_PATH } from '../../../constants/tools'
import { GET_FINANCE } from '../../../constants/urls'
import { GlobalContext } from '../../GlobalContext'

export default function Finance() {
    const [data, setData] = useState([])
    const { lang } = useContext(GlobalContext)
    const getData = async() => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_FINANCE)}`)
        if(info?.data?.length) {
        setData(info.data)
        }
    }

    useEffect(() => {
        getData()
    }, [lang])

    return (
        <div style={{  width: '75%' }} className='d-flex flex-column align-items-center container-fluid mb-3'>
            {data?.length ? data?.map((item, index) => (
                <table className="table" key={index}>
                <thead>
                    <tr>
                        <th scope="col" className='table-text'>#</th>
                        <th scope="col" className='table-text' dangerouslySetInnerHTML={{ __html: item?.title }} />
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className='table-text' scope="row">{index + 1}</th>
                        <td className='table-text' dangerouslySetInnerHTML={{ __html: item?.content }}/>
                    </tr>
                </tbody>
            </table>
            )) : ''}
        </div>
    )
}