import React, { useContext, useEffect, useState } from 'react'
import NewsPage from '../News/News'
import { GlobalContext } from '../../GlobalContext'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { GET_NEWS } from '../../../constants/urls'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";
import { Autoplay, Pagination, Navigation } from "swiper";

const Xizmat = () => {

    const [data, setData] = useState([])
    const { lang } = useContext(GlobalContext)

    const getData = async () => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_NEWS)}`)
        if (info?.data?.length) {
            setData(info.data.reverse())
        }
    }

    useEffect(() => {
        getData()
    }, [lang])

    const { t } = useTranslation();

    return (
        <>



            <div className="Photo">
                <div className="containerr">
                    <div className="row">

                        <div className="col-12">
                            <div className="h_news_name_box">
                                <div className="h_news_name">
                                    Matbuot anjumani
                                </div>
                            </div>

                        </div>

                        <div className="col-12 mt-5 mb-5">
                            <img className='w-100 h-100' src="/img/anj_1.png" alt="" />
                        </div>

                        <div className="ph_h">“Ekologik ekspertiza to‘g‘risida”gi O‘zbekiston Respublikasi Qonunining yangi tahrirdagi loyihasi muhokamasi bo‘lib o‘tdi</div>
                        <a href='https://telegra.ph/Ekologik-ekspertiza-togrisidagi-Ozbekiston-Respublikasi-Qonunining-yangi-tahrirdagi-loyihasi-muhokamasi-04-03' className="ph_p">
                            Tadbirning asosiy maqsadi  – yangi tahrirda qabul qilingan qonun loyihasidan amaldagi qonunchilikni takomillashtirish va fuqarolar va tadbirkorlik subyektlari uchun ijobiy natijalar to'g'risida ommaviy axborot vositalari orqali keng jamoatchilikni xabardor qilish.
                        </a>
                        <div className="col-12">
                            <div className="h_news_name_box">
                                <div className="h_news_name">
                                    Infografika
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4 mt-5 mb-5">
                            <img className='w-100 h-100' src="/img/15.png" alt="" />
                        </div>
                        <div className="col-lg-4 mt-5 mb-5">
                            <img className='w-100 h-100' src="/img/16.png" alt="" />
                        </div>
                        <div className="col-lg-4 mt-5 mb-5">
                            <img className='w-100 h-100' src="/img/17.png" alt="" />
                        </div>

                        <div className="col-12">
                            <div className="h_news_name_box">
                                <div className="h_news_name">
                                    Fotogalereya
                                </div>
                            </div>

                        </div>

                        <div className="col-12">
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 3500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <img className='w-100' src="/img/ph_7.png" alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img className='w-100' src="/img/ph_6.png" alt="" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img className='w-100' src="/img/ph_3.png" alt="" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img className='w-100' src="/img/ph_4.png" alt="" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img className='w-100' src="/img/ph_5.png" alt="" />
                                </SwiperSlide>


                            </Swiper>

                            <video className='mt-5 mb-5 w-100' controls muted src="/video/rolik_AOK_fx_fx_tg_fx.mp4"></video>

                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="news">
                <div className="news__info">
                    <h2>{t("news")}</h2>
                </div>
                <div className="news__cards ">

                    {data &&
                        data.slice(0, 3).map((item, index) => (
                            <div key={index} className="row mb-5" data-aos="fade-right">
                                <div className=" col-lg-5 mb-lg-0 mb-5">
                                    <Link to={`/readmorenews/${item.id}`} className="news__card__img">
                                        <img className="news-images" src={item.image} alt={item.title} />
                                    </Link>
                                </div>
                                <div className=" col-lg-7 ">
                                    <div className="news__card__info">
                                        <div className="news__card__info__text">
                                            <h3>
                                                {item.title}
                                            </h3>
                                        </div>
                                        <div className="news__card__info__btn">
                                            <p dangerouslySetInnerHTML={{ __html: item?.content?.slice(0, 1000) }} />
                                            <Link to={`/readmorenews/${item.id}`} className="read-more-link">
                                                {t("more")}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                </div>
            </div> */}


        </>
    )
}

export default Xizmat