// import Carousel from 'react-bootstrap/Carousel';
import React, { useContext, useEffect } from 'react';
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import img1 from  '../../../assets/images/icons/image 27.png'
import img2 from  '../../../assets/images/icons/read-more1.jpg'
import img5 from '../../../assets/images/icons/President.jpg'
import img3 from  '../../../assets/images/icons/IMG_3080.png'
import img4 from  '../../../assets/images/icons/4R6A1669 1.png'

// import "./styles.css";

// import { Autoplay, Pagination, Navigation } from "swiper";

import { Autoplay, EffectCoverflow, Pagination } from 'swiper';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_PATH } from '../../../constants/tools';
import { GlobalContext } from '../../GlobalContext';
import { GET_NEWS } from '../../../constants/urls';
import { Link } from 'react-router-dom';



function CarouselFadeExample({changeLang}) {

  const { t } = useTranslation();
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async () => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_NEWS)}`)
    if (info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])
  return (
    <div className='CarouselFadeExample'>
      <div className="containerr">
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          // depth: 10,
          // modifier: 1,
          slideShadows: true,
          
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination ,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={img5}/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img2}/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img3}/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={img4}/>
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
  );
}

export default CarouselFadeExample;