const translationsRu = {
  home: "ГЛАВНЫЙ",
  about: "О ЦЕНТРЕ",
  activity: "ДЕЯТЕЛЬНОСТЬ",
  services: "УСЛУГИ",
  services2: "Услуги",
  contact: "КОНТАКТЫ",
  herotittle: "ЦЕНТР ГОСУДАРСТВЕННЫЙ ЭКОЛОГИЧЕСКОЙ ЭКСПЕРТИЗЫ",
  staff: "СОТРУДНИКИ",
  followUs: "Подписывайтесь на нас",
  number: "Номер",
  address: "Адрес",
  email: "Эл. адрес",
  latestnews: "Последние новости",
  news: "Новости",
  documents: "Законодательные документы",
  hotlines: "Доверенный телефон",
  services_info: "Список интерактивных сервисов, доступных через портал",
  see_all: "Увидеть все",
  send1: "Отправить",
  more: "Подробнее",
  xizmat1: "Проведение государственной экологической экспертизы проектов государственных программ, концепций и схем развития социальной сферы, а также планов управления особо охраняемыми природными территориями",
  xizmat2: "Прохождение государственной экологической экспертизы проектов производительных сил, концепции и развития отраслей экономики",
  xizmat3: "Проведение государственной экологической экспертизы материалов для выбора земельных участков под все виды строительства",
  xizmat4: "Государственная экологическая экспертиза предпроектной и проектной документации по видам деятельности",
  xizmat5: "Прохождение государственной экологической экспертизы проектов нормативно-технических и инструктивно-методических документов, регулирующих хозяйственную и иную деятельность, связанную с использованием природных ресурсов",
  xizmat6: "Прохождение государственной экологической экспертизы документов, связанных с созданием новых видов продукции, технологий, материалов, веществ",
  xizmat7: "Проведение государственной экологической экспертизы действующих предприятий и иных объектов, оказывающих негативное воздействие на природную среду и здоровье граждан",
  xizmat8: "В целях придания территориям статуса охраняемых природных территорий, зон чрезвычайной экологической ситуации и зон экологического бедствия государственная экологическая экспертиза материалов их комплексного обследования",
  xizmat9: "Прохождение градостроительной документации через государственную экологическую экспертизу",
  connect_span: "Связаться",
  connect: "с нами",
  naming: "Ф.И.О.",
  ukaz: "Законодательные документы",
  pgone: "Телефон номер",
  maill: "Электронная почты",
  texx: "Содержание сообщения",
  region_tittle: "ИНФОРМАЦИЯ о сотрудниках Департамента экологии и охраны окружающей среды Республики Узбекистан «Экологический экспертный центр» Республики Каракалпакстан, областей и Ташкентского городского управления",
  user_data: "Время и место рождения",
  fio: "Ф.И.О",
  user_tell: "Номер телефона",
  appointment: "Должность ",
  education: "Образование и опыт",
  language: "",
  list: "Подробнее",
  location: "г.Ташкент, Мирзо-Улугбекский район, улица Сайрама, 15",

  rahbar1_1: "Руководители Филиалов",
  rahbar1: "О сотрудниках Центра государственной экологической экспертизы",
  rahbar2: "Ф.И.О",
  rahbar3: "Прием осуществляется по следующему адресу",
  rahbar4: "Телефон номер",
  rahbar5: "Должность",
  rahbar6: " E-mail адрес",
  rahbar8: "Время приема",

  rahbar_14: `Видеогалерея`,

  nav_19_1: "Международные связи",
  rahbar_2: `Выводы`,
  rahbar_3: `Рейтинг проектора`,
  rahbar_4: `Информационная служба`,
  rahbar_5: `Общественные слушания`,

  nav_1_2: `Организационная структура`,

  nav_3_2: `Региональные филиалы`,
  nav_4_2: `Законодательные документы`,
  nav_5_2: `Открытые данные `,
  text_1:"Информация дополняется...",

  // korrupsion

  kor1: "ЦЕНТР ГОСУДАРСТВЕННОЙ ЭКОЛОГИЧЕСКОЙ ЭКСПЕРТИЗЫ ГОСУДАРСТВЕННОГО КОМИТЕТА РЕСПУБЛИКИ УЗБЕКИСТАН ПО ЭКОЛОГИИ И ОХРАНЕ ОКРУЖАЮЩЕЙ СРЕДЫ “ОСНОВНЫЕ НАПРАВЛЕНИЯ ПРОТИВОДЕЙСТВИЯ КОРРУПЦИИ“",
  kor2: "Введение",
  kor3: "Определяющие факторы коррупции и причины ее возникновения",
  kor4: "Основные направления государственной политики по противодействию коррупции",
  kor5: "Развитие системы противодействия коррупции Республики Узбекистан",
  kor6: "Коррупция",
  kor7: "Коррупция (от лат. corruptio — тошнить, продавать за взятку) - незаконное использование лицом своего служебного или служебного положения с целью получения материальной или нематериальной выгоды в личных интересах или в интересах других лиц, а также незаконное предоставление такой выгоды, подкуп должностных лиц, продажа их за взятку; продажа выборных, назначаемых должностей.;",
  kor8: "Лоббизм (система законодательных органов монополий и институтов, занимающихся оказанием давления на должностных лиц). На международном уровне Локсид-дело о приобретении компанией высших государственных чиновников при продаже самолетов в Японию в 1970-х годах является примером некоторых из крупнейших проявлений коррупции. В крупных сделках для взяточничества используется практика взимания более высокой платы за обслуживание.",
  kor9: "Проявления конфликта интересов",
  kor10: "Лоббизм (англ. вестибюль – кулуар, коридор)",
  kor11: "специфический тип политического влияния неформалов на принятие официальных решений, встречаясь между собой",
  kor12: "Лидерство, фаворитизм (фр. фаворит)",
  kor13: "близкий прием и поддержка некоторых сотрудников, находящихся под защитой",
  kor14: "Протекционизм (лат. помощь)",
  kor15: "содействие назначению определенных лиц на должность",
  kor16: "Кумовство (лат. непосвященный)",
  kor17: "работа с узкими групповыми интересами в государственных структурах или иных структурах на этнических принципах",
  kor18: "Клиентелизм (англ. клиентоориентированность, много чего. клиентела - клиент)",
  kor19: "должностное лицо в должности и сочувствующие ему лица (должностные лица, предприниматели, политические группы)",
  kor20: "Кронизм (англ. единомышленники, знакомые знатоки, групповуха)",
  kor21: "выдача позиций на основе знакомства",
  kor22: "Определяющие факторы коррупции и причины ее возникновения",
  kor23: "Под коррупционным правонарушением понимается деяние, имеющее признаки коррупции, за совершение которого законодательством предусмотрена ответственность",
  kor24: "Термин коррупция происходит от латинского слова corruptio, что означает подрыв, срыв системы, отклонение от курса взяток",
  kor25: "Под коррупцией понимается незаконное использование лицом своей карьеры или служебного положения в целях получения материальной или нематериальной выгоды в личных интересах или интересах других лиц, а также незаконное предоставление такой выгоды",
  kor26: "История борьбы с коррупцией",
  kor27: "Арасту",
  kor28: "Самое главное в любом государственном устройстве-это то, что посредством законов и процедур работа должна быть организована таким образом, чтобы должностные лица не могли извлекать выгоду из богатства косвенным путем",
  kor29: "Цицерон",
  kor30: "Он пришел бедным в богатую провинцию и стал богатым из бедной провинции",
  kor31: "Эдмунд Берк",
  kor32: "В обществе, где коррупция бьет барк, свобода не может царить долго",
  kor33: "Питер Айген",
  kor34: "Коррупция-это злоупотребление государственной властью во имя личной выгоды",
  kor35: "Существует несколько факторов происхождения коррупционных преступлений",
  kor36: "Антропологические факторы",
  kor37: "Конфликтная природа человека, постоянная борьба добра и зла, стремление человека к большей прибыли с меньшими усилиями.",
  kor38: "Социальные факторы",
  kor39: "Социальная стратификация общества, наличие “групп риска”, нарушение трудового законодательства, несоблюдение принципов социальной справедливости при использовании труда рабочих-мигрантов, женщин и подростков.",
  kor40: "Правовые факторы",
  kor41: "Несовершенство правовой основы предпринимательства, наличие противоречия между изменяющимися условиями рыночного хозяйства и существующей законодательной базой, отсутствие регулирования домашнего хозяйства.",
  kor42: "Моральные факторы",
  kor43: "противоречия между законодательством и этическими основами предпринимательства, противоречия между интересами государства и общества, государства и индивида; игнорирование национальных традиций, влияние религиозных предписаний и др.",
  kor44: "Антропологические факторы",
  kor45: "Высокий уровень трансакционных издержек предпринимательской деятельности (“цена законопослушности”), конкуренция в рамках рыночной экономики, неравномерное развитие различных секторов экономики и др.",
  kor46: "Политические факторы",
  kor47: "соотношение между властью и крупным капиталом-это соотношение между формированием олигархии, властью и властными методами воздействия на экономическую деятельность.",
  kor48: "Бытовая коррупция",
  kor49: "В основном это распространено в отношениях должностных лиц и государственных служащих с гражданами в их повседневной работе",
  kor50: "Существует несколько видов коррупции",
  kor51: "Коррупция в бизнесе",
  kor52: "В основном это распространено в отношениях должностных лиц и государственных служащих с гражданами в их повседневной работе",
  kor53: "Коррупция в высших органах власти",
  kor54: "В основном это распространено в отношениях должностных лиц и государственных служащих с гражданами в их повседневной работе",
  kor55: "Факторами, способствующими совершению коррупционных преступлений, являются недостаточный организационно-управленческий и социальный контроль над этим пороком.",
  kor56: "К ним относятся",
  kor57: "Недостатки в планировании, нарушение участниками договорных требований, недостатки в организации служебной деятельности (распределение обязанностей, определение компетенции, большой объем работ и др.).",
  kor58: "Допустить бесхозяйственность и перерасход (нерациональное использование энергоресурсов, материальных и людских ресурсов), дефекты воспитательной работы.",
  kor59: "Отсутствие контроля за прибылью и расходами должностных лиц, служащих в деятельности правоохранительных и контролирующих органов и выполнением ими служебных обязанностей, а также пренебрежение коррупционными обстоятельствами.",
  kor60: "Недостатки в подборе и расстановке кадров (инбридинг лиц без образования и опыта или без доверия, прием на работу на основе родства, знакомства и тому подобное).",
  kor61: "Слабый биллинг и контроль.",
  kor62: "Причины возникновения коррупционных ситуаций",
  kor63: "Более низкие зарплаты в государственном секторе, чем в частном секторе",
  kor64: "Экономика страны в основном контролируется государством",
  kor65: "Установление единоличного правления в государстве на определенных видах услуг",
  kor66: "Разрыв связей органов государственного управления с народом",
  kor67: "Отсутствие экономической стабильности",
  kor68: "Этническая неоднородность народа",
  kor69: "Низкие темпы экономического развития (прирост ВВП на душу населения)",
  kor70: "Наличие радикально-религиозных взглядов в обществе",
  kor71: "Низкие темпы экономического развития (прирост ВВП на душу населения)",
  kor72: "Низкая правовая культура общества",
  kor73: "Существование норм права, которые трактуются двояко",
  kor74: "Политическая нестабильность в стране",
  kor75: "Слабость общественного контроля граждан за деятельностью государственных органов",
  kor76: "Высокий уровень политического покровительства, местничества, родственных и кулинарных отношений в обществе и государственном управлении",
  kor77: "Борьба с коррупцией и организованной преступностью, а также эффективное решение вопросов профилактики правонарушений остаются одними из наших приоритетов",
  kor78: "Президент Республики Узбекистан Шавкат Мирзиеев",
  kor79: "Республика Узбекистан осуществляет международное сотрудничество в области противодействия коррупции с рядом международных организаций",
  kor80: "EGMONT GROUP (Ассоциация финансовой разведки)",
  kor81: "UNODC (Управление ООН по наркотикам и преступности)",
  kor82: "ПРООН Европейского союза (программа развития ООН)",
  kor83: "OSCE (Организация по безопасности и сотрудничеству в Европе)",
  kor84: "OECD (Организация экономического сотрудничества и развития)",
  kor85: "FATF (Организация по борьбе с отмыванием денег)",
  kor86: "Приоритетные направления государственной политики по противодействию коррупции в Республике Узбекистан",
  kor87: "Приоритетные направления государственной политики по противодействию коррупции в Республике Узбекистан",
  kor88: "Снятие юридических ограничений и организационных барьеров для развития предпринимательства",
  kor89: "Совершенствование правовых и институциональных основ регулирования предоставления государственных услуг и административных процедур",
  kor90: "Проведение административных реформ, совершенствование функций государственных органов, передача негосударственных функций частному сектору",
  kor91: "Совершенствование целей, задач и функций судебных и правоохранительных органов в сфере противодействия коррупции",
  kor92: "Налаживание системы связи между органами государственной власти, обществом и институтами гражданского общества",
  kor93: "Совершенствование системы подготовки, переподготовки и повышения квалификации кадров, формирование высоких моральных норм",
  kor94: "Совершенствование целей, задач и функций судебных и правоохранительных органов в сфере противодействия коррупции",
  kor95: "Korruptsiyaviy jinoat va huquqbuzarliklar sodir etilishini oldini olish  maqsadida Huquqbuzarliklar profilaktikasi, Huquqiy axborotni tarqatish  va undan foydalanishni ta'minlash, Korruptsiyaga qarshi kurashish, Sudьyalar oliy kengashi, Davlat xaridlari, Jabrlanuvchilarni, guvohlarni va jinoyat protsessining boshqa ishtirokchilarini himoya qilish to‘g‘risidagi va bir qator boshqa konunlar qabul qilindi",
  kor96: "Развитие системы противодействия коррупции в Республике Узбекистан:",
  kor97: "Своевременное выявление коррупционных правонарушений, их пресечение, устранение их последствий, причин и условий, которые их допускают, обеспечение принципа неотвратимости ответственности за совершение коррупционных правонарушений.",
  kor98: "Повышение правосознания и правовой культуры населения, формирование в обществе нетерпимого отношения к коррупции",
  kor99: "Реализация мер по предупреждению коррупции во всех сферах жизни государства и общества;",
  kor100: "Система органов и учреждений, непосредственно осуществляющих профилактику коррупционных правонарушений",
  kor101: "Органы внутренних дел",
  kor102: "Органы государственной безопасности",
  kor103: "Органы Государственной таможенной службы",
  kor104: "Органы прокуратуры",
  kor105: "Органы правосудия",
  kor106: "К мерам, направленным на снижение прибыли от коррупционной деятельности, можно отнести",
  kor107: "Внедрение системы справедливой оплаты труда и иного социального обеспечения государственных служащих",
  kor108: "Поощрение честности",
  kor109: "Введение обязательного увольнения за нарушение антикоррупционных правил",
  kor110: "Отмена карьерного иммунитета",
  kor111: "Предоставление справедливых и одинаковых условий для всех, а также возможность карьерного роста и продвижения по службе",
  kor112: "Создание достойных условий для выхода на пенсию и т.д.",
  kor113: "Информация, содержащая признаки коррупционных правонарушений и коррупции в отношении сотрудников центра и его территориальных филиалов, направляется в управление Центра по противодействию коррупции и комплаенс-контролю– (93) 593 48 89; (71) 203 00 22” (1019) или непосредственно в отдел кадров 93 386 20 10; “(71) 203 00 22” (1010) желательно выдавать через стационарные и мгновенные мобильные телефоны",
  kor114: "",
  ish:"Время работы",
  fot1: "Связаться с нами",
  rahbar4_4: "Центральный аппарат",
  rahbar1_7: "Лица, ответственные за размещение публичной информации",
  nav_1: `Цели и направления`,
  nav_2: `Основы юридической деятельности`,
  nav_3: `Центральный аппаратно-структурный отдел`,
  nav_4: `История центра`,
  nav_5: `Руководства`,
  nav_6: `Новости`,
  nav_7: `Молодежная политика`,
  nav_8: `Индикаторы активности`,
  nav_9: `Международные отношения`,
  nav_10: `Борьба с коррупцией`,
  nav_11: `Функци и задачи`,
  nav_12: `Молодежная политика`,
  nav_13: `Законодательство`,
  dav1: "Государственное учреждение",
  dav2: "Рабочее время: ",
  dav3: "Понедельник – Пятница, 9:00 -18:00",
  dav4: "Выходной:",
  dav5: "Суббота, Воскресенье",
  dav6: "Тел: ",
  dav7: "Exat",
  dav8: "E-почта",
  nav_14: `Государственные услуги`,
  nav_15: `Открытые данные`,
  nav_16: `Часто задаваемые вопросы и ответы`,
  nav_17: `Услуги`,
  nav_18: `Открытые данные по финансам`,
  nav_19: `Вакансии`,
  nav_20: `Лица, ответственные за размещение публичной информации`,
  nav_21: `Просмотр информационного запроса`,
  nav_22: `Служба контактной информации`,
  nav_23: `Реестр заключений государственной экологической экспертизы`,
  nav_24: `Утвержденные полугодовые и годовые планы работы государственных органов и организаций`,
  nav_25: `Отчеты о деятельности центра`,
  nav_26: `Сведения о результатах рассмотрения обращений граждан и каналах обращения`,
  nav0: "Пресс-конференция",
  nav_info_1: "Почта",
  nav_info_2: "Телеграм канал",
  nav_info_3: "Техническая поддержка",
  nav_info_4: "ИНСТРУКЦИЯ по направлению заказа на государственную экологическую экспертизу",

  calculator: `Калькулятор`,

  vacancy_company: "Компания",
  vacancy_location: "Адрес",

  nav_test: `Сайт находится в тестовом режиме`,


  kr1: "Борьба с коррупцией",
  kr2: "Январь",
  kr3: "Кодекс этики сотрудников Государственного центра экологической экспертизы",
  kr4: "Положение об антикоррупционной политике в Государственном центре экологической экспертизы",
  kr5: "Июль",
  kr6: "Об организации оперативного расследования обращений, поступивших от юридических и физических лиц через социальные сети и средства массовой информации",
  kr7: "Краткая биография",
  kr8: "Функции",

  kr10: "ТРУДОВАЯ ДЕЯТЕЛЬНОСТЬ",
  kr9: "Мухамедов Гайрат Аминович родился 19 февраля 1966 года в Бухарской области. Образование – высшее, в 1992 году Ташкентский государственный аграрный университет, инженер лесного хозяйства, в 2004 году магистр государственного и общественного строительства, Академия государственного и общественного строительства при Президенте Республики Узбекистан, в 2005 году Бухарский государственный университет, экономический факультет. . закончил учебу.",
  kr11: "1983-2001. - Начал карьеру на различных должностях в сельском и лесном хозяйстве.",
  kr12: "2002-2011 гг. - в должности директора Охангаронского лесхоза государственного природного парка «Угам-Чоткаль», Ташкентская область.",
  kr13: "2001-2002 гг. - Начальник управления лесного хозяйства Бухарской области.",
  kr14: "2011-2015 гг. - Председатель Комитета охраны природы Ташкентской области",
  kr15: "2015-2016 гг. - Госкомприроды Республики Узбекистан в должности начальника Республиканской государственной инспекции по охране и рациональному использованию животного и растительного мира",
  kr16: "2016-2021 гг. - “PETROMARUZ OVERSEAS” Он является исполнительным директором британской компании в Узбекистане, генеральным директором.",
  kr17: "2021-2021 гг. - “SURHANCEMENTINVEST” в должности генерального директора иностранного предприятия",
  kr18: "2021-2022 гг. - “В должности заместителя председателя ассоциации «Хлопко-текстильные кластеры Узбекистана».",
  kr19: "2022 гг -Работает генеральным директором государственного учреждения «Государственный центр экологической экспертизы» Министерства экологии, охраны окружающей среды и изменения климата Республики Узбекистан.",
  kr20: "Айтмуратов Тимурлан Парахатович родился 5 апреля 1991 года в Чимбойском районе Республики Каракалпакстан. Образование – высшее, в 2013 году окончил Ташкентский институт инженеров ирригации и механизации сельского хозяйства по специальности «экология и охрана окружающей среды», в 2015 году окончил Ташкентский химико-технологический институт (магистр) по специальности «Химическая технология неорганических веществ». вещества.",
  kr21: "2013-2017 гг. - Начинал с должности руководителя, главного специалиста ГУП «Государственный центр экологической сертификации и стандартизации» при Госкомэкологии и охране окружающей среды Республики Узбекистан.",
  kr22: "2017-2021 гг. - в должности директора государственного унитарного предприятия «Государственный центр экологической сертификации и стандартизации» при Государственном комитете экологии и охраны окружающей среды Республики Узбекистан.",
  kr23: "2021-2022 гг. - Директор ГУП «Ташкентский городской государственный экологический экспертный центр» при Государственном комитете экологии и охраны окружающей среды Республики Узбекистан.",
  kr24: "2022 год - х.в. - Работает первым заместителем генерального директора государственного учреждения «Государственный центр экологической экспертизы» Министерства экологии, охраны окружающей среды и изменения климата Республики Узбекистан.",

  kr25: "Ши-сян Вячеслав Викторович родился 22 февраля 1982 года в Ташкентской области. Образование высшее – магистр, в 2006 году окончил Ташкентский химико-технологический институт по специальности «охрана окружающей среды».",
  kr26: "2003-2006 гг. - Начинал он инспектором комитета охраны природы Ташкентской области.",
  kr27: "2006-2007 гг.- в должности руководителя комитета охраны природы Ташкентской области.",
  kr28: "2007-2008 гг. - в должности заместителя генерального директора Общества с ограниченной ответственностью «Табиат Курилиш Проект».",
  kr29: "2007-2008 гг. - в должности Генерального директора Общества с ограниченной ответственностью «Эколигиат-Норм».",
  kr30: "2012-2013 гг. - в должности главного специалиста «БОШДАВЕКОЭКСПЕРТИЗА».",
  kr31: "2013-2014 гг. - в должности заместителя руководителя  «БОШДАВЕКОЭКСПЕРТИЗА».",
  kr32: "2014-2022. - в должности генерального директора общества с ограниченной ответственностью «ЭКО ПРОМ КОНСАЛТИНГ».",
  kr33: "2022 год - х.в. - Работает в должности заместителя генерального директора Государственного учреждения «Центр экологической экспертизы» Министерства экологии, охраны окружающей среды и изменения климата Республики Узбекистан.",

  kr34: "Опрос",
  kr35: "Вам нравиться новый дизайн сайта",
  kr36: "Отлично",
  kr37: "Хорошо",
  kr38: "Удовлетворительно",
  kr39: "Голосовать",
  kr40: "Результаты",

  rahbar_10: `Пресс-релиз`,
  rahbar_11: `Пресс-конференция`,
  rahbar_12: `Инфографика`,
  rahbar_13: `Фотогалерея`,
  kar1: "Ташкентский областной филиал Государственного центра экологической экспертизы",
  kar2: "Выбрать область",
  hamk: "Партнеры",
  kar3: "г. Ташкент",
  kar4: "Ташкентский область",
  kar5: "Андижанский область",
  kar6: "Ферганский область",
  kar7: "Наманганский область",
  kar8: "Сырдаринский область",
  kar9: "Джиззакский область",
  kar10: "Самаркандский область",
  kar11: "Кашкадаринский область",
  kar12: "Сурхандаринский область",
  kar13: "Навоийскйи область",
  kar14: "Хорезмский область",
  kar15: "Республика Каракалпакстан",

};

export default translationsRu;