import React from 'react'
import { useTranslation } from "react-i18next";
const Partner = () => {

    const { t } = useTranslation();


    return (
        <>

            <div className="Partner">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="part_name_box">
                                <div className="part_h">
                                {t('hamk')}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-2 col-md-4 col-sm-6  ">
                            <img className='part_img' src="/img/part_1.jpg" alt="" />
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6  ">
                            <img className='part_img' src="/img/part_2.png" alt="" />
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6  ">
                            <img className='part_img' src="/img/part_3.jpg" alt="" />
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6  ">
                            <img className='part_img' src="/img/part_4.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Partner